import Card from "react-bootstrap/Card";
import scopusLogo from "../../../../../images/resize/scopus-logo.png";

import poweredByScopus from "../../../../../images/PoweredbyScopus.png";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

function ScopusIssnDetails({ content }) {
  const [optionsSJR, setOptionsSJR] = useState({});
  const [seriesSJR, setSeriesSJR] = useState([]);
  const [optionsSNIP, setOptionsSNIP] = useState({});
  const [seriesSNIP, setSeriesSNIP] = useState([]);
  useEffect(() => {
    if (content !== null) {
      if (content) {
        const sjrCategories = content.SJRList?.SJR?.map(
          (item) => item["@year"],
        );
        const sjrData = content.SJRList?.SJR?.map((item) => item["$"]);
        const snipCategories = content.SNIPList?.SNIP?.map(
          (item) => item["@year"],
        );
        const snipData = content.SNIPList?.SNIP?.map((item) => item["$"]);
        setOptionsSJR({
          dataLabels: {
            enabled: true,
            enabledOnSeries: true,
            textAnchor: "middle",
            distributed: false,
            style: {
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold",
              colors: ["#000"],
            },
          },
          chart: {
            id: "sjr-chart",
            animations: {
              enabled: true,
              easing: "easeinout",
              speed: 800,
              animateGradually: {
                enabled: true,
                delay: 150,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350,
              },
            },
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            categories: sjrCategories,
            labels: {
              show: true,
              style: {
                fontWeight: "bold",
              },
            },
          },
          yaxis: {
            labels: {
              show: true,
              style: {
                fontWeight: "bold",
              },
            },
          },
          colors: [
            "#edcb5c",
            "#daed5c",
            "#b5ed5c",
            "#5ced68",
            "#5ced9d",
            "#5cedd2",
            "#5cd2ed",
            "#5ca7ed",
            "#5c66ed",
            "#805ced",
            "#9d5ced",
            "#c65ced",
            "#e85ced",
            "#ed5cbd",
            "#ed5c83",
          ],
          title: {
            text: "SCImago Journal Rank",
            align: "center",
            margin: 4,
            offsetY: 15,
            style: {
              fontSize: "14px",
              color: "black",
            },
          },
        });
        setSeriesSJR([
          {
            name: "SJR",
            data: sjrData,
          },
        ]);
        setOptionsSNIP({
          dataLabels: {
            enabled: true,
            enabledOnSeries: true,
            textAnchor: "middle",
            distributed: false,
            style: {
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold",
              colors: ["#000"],
            },
          },
          chart: {
            id: "snip-chart",
            animations: {
              enabled: true,
              easing: "easeinout",
              speed: 800,
              animateGradually: {
                enabled: true,
                delay: 150,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350,
              },
            },
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            categories: snipCategories,
            labels: {
              show: true,
              style: {
                fontWeight: "bold",
              },
            },
          },
          yaxis: {
            labels: {
              show: true,
              style: {
                fontWeight: "bold",
              },
            },
          },
          colors: [
            "#edcb5c",
            "#daed5c",
            "#b5ed5c",
            "#5ced68",
            "#5ced9d",
            "#5cedd2",
            "#5cd2ed",
            "#5ca7ed",
            "#5c66ed",
            "#805ced",
            "#9d5ced",
            "#c65ced",
            "#e85ced",
            "#ed5cbd",
            "#ed5c83",
          ],
          title: {
            text: "Source Normalized Impact per Paper",
            align: "center",
            margin: 4,
            offsetY: 15,
            style: {
              fontSize: "14px",
              color: "black",
            },
          },
        });
        setSeriesSNIP([
          {
            name: "SNIP",
            data: snipData,
          },
        ]);
      }
    }
  }, [content]);

  return (
    <>
      <Card
        className="tab-color-scopus-detail"
        style={{ marginTop: "3rem" }}
        bg="light"
        text="black"
      >
        <Card.Body>
          {!content.notFound ? (
            <>
              <Row>
                <Col xs={12} sm={2}>
                  <a
                    href={content.link ?? `https://www.scopus.com/home.uri`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Card.Img
                      variant="top"
                      alt="Web of Science"
                      style={{ width: "8rem" }}
                      src={scopusLogo}
                    />
                  </a>
                </Col>

                <Col style={{ alignItems: "center" }}>
                  <Chart
                    options={optionsSJR}
                    series={seriesSJR}
                    type="bar"
                    width="300"
                  />
                </Col>
                <Col>
                  <Chart
                    options={optionsSNIP}
                    series={seriesSNIP}
                    type="bar"
                    width="300"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={2}></Col>
                <Col>
                  {" "}
                  <p style={{ color: "#445e5f", fontWeight: "bold" }}>
                    CiteScore{" "}
                    <a
                      style={{ color: "#445e5f", fontWeight: "bold" }}
                      href={content.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {content.citeScoreYearInfoList.citeScoreCurrentMetric} -{" "}
                      {content.citeScoreYearInfoList.citeScoreCurrentMetricYear}
                    </a>{" "}
                  </p>
                </Col>
                <Col>
                  {" "}
                  <p style={{ color: "#445e5f", fontWeight: "bold" }}>
                    {" "}
                    CiteScore Tracker{" "}
                    <a
                      style={{ color: "#445e5f", fontWeight: "bold" }}
                      href={content.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {content.citeScoreYearInfoList.citeScoreTracker} -{" "}
                      {content.citeScoreYearInfoList.citeScoreTrackerYear}
                    </a>{" "}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={2}></Col>
                <Col>
                  <p>
                    El cálculo de CiteScore se basa en el número de citas de
                    documentos (artículos, revisiones, comunicaciones a
                    congresos, capítulos de libros y documentos de datos) de una
                    revista a lo largo de cuatro años, dividido por el número de
                    los mismos tipos de documentos indexados en Scopus y
                    publicados en esos mismos cuatro años.
                    <br></br>CiteScore Tracker se calcula del mismo modo que
                    CiteScore, pero para el año en curso en lugar de los años
                    anteriores completos.
                  </p>

                  <img
                    style={{ width: "10rem" }}
                    src={poweredByScopus}
                    alt="powered_by_scopus"
                  />

                  <div style={{ fontSize: "0.8em", color: "grey" }}>
                    <br></br>
                    Más información en “
                    <a
                      href="https://service.elsevier.com/app/answers/detail/a_id/14880/supporthub/scopus/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      CiteScore Journal Metric - FAQs
                    </a>
                    ”<br></br>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col xs={12} sm={2}>
                <a
                  href="https://www.scopus.com/home.uri"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Card.Img
                    variant="top"
                    alt="Web of Science"
                    style={{ width: "10rem" }}
                    src={scopusLogo}
                  />
                </a>
              </Col>
              <Col>
                <p>{content.error}</p>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default ScopusIssnDetails;
