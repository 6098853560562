import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import openAlexlogo from "../../../../../images/resize/OpenAlex-logo.png";
import ChartsBar from "../../ChartsBar";

function OpenAlexIssnDetails({ content }) {
  const counts_by_year = content?.counts_by_year ?? undefined;
  const id = content?.id ?? null;

  return (
    <>
      <Card
        className="tab-color-openalex-detail"
        bg="light"
        style={{ marginTop: "1rem", height: "100%" }}
      >
        <Card.Body>
          <Row>
            <Col xs={12} sm={2}>
              <a href={id} target="_blank" rel="noreferrer">
                <Card.Img
                  variant="top"
                  alt="Open Alex Logo"
                  style={{ width: "7rem" }}
                  src={openAlexlogo}
                />
              </a>
            </Col>

            {content && content.notFound ? (
              <Col style={{ textAlign: "center" }}>{content.error}</Col>
            ) : (
              <>
                {content?.works_count && (
                  <Col style={{ textAlign: "center" }}>
                    <p>
                      <span style={{ fontSize: "1.2rem" }}>Artículos</span>
                      <br />
                      <b style={{ fontSize: "2rem" }}>{content.works_count}</b>
                    </p>
                  </Col>
                )}
                {content?.cited_by_count && (
                  <Col style={{ textAlign: "center" }}>
                    <p style={{ fontSize: "1.2rem" }}>
                      Cantidad de citas
                      <br />
                      <b style={{ fontSize: "2rem" }}>
                        {content.cited_by_count}
                      </b>
                    </p>
                  </Col>
                )}
              </>
            )}
          </Row>
          {counts_by_year && counts_by_year.length > 0 ? (
            <Col>
              <ChartsBar citedYear={counts_by_year} />{" "}
            </Col>
          ) : null}
        </Card.Body>
      </Card>
    </>
  );
}

export default OpenAlexIssnDetails;
