import Card from "react-bootstrap/Card";
import scopusLogo from "../../../../../images/resize/scopus-logo.png";
import { Button, Col, Row } from "react-bootstrap";

function ScopusDoiDetails({ content }) {
  return (
    <Card
      className="tab-color-scopus-detail"
      bg="light"
      style={{ marginTop: "2rem", height: "100%" }}
    >
      <Card.Body>
        <Row>
          <Col xs={12} sm={6}>
            <a
              href={content.link ?? `https://www.scopus.com/home.uri`}
              target="_blank"
              rel="noreferrer"
            >
              <Card.Img
                variant="top"
                alt="Web of Science"
                style={{ width: "8rem" }}
                src={scopusLogo}
              />
            </a>
          </Col>
          <Col xs={12} sm={6}>
            {!content.notFound ? (
              <>
                <Button
                  id="scopus-citedby"
                  variant="outline-success"
                  target="_blank"
                  href={content.link}
                >
                  Buscar citas en Scopus
                </Button>
              </>
            ) : (
              // : <p>{content.error}</p>
              <p>Recurso no encontrado</p>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default ScopusDoiDetails;
