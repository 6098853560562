import logoSedici from "../images/logoSedici.png";
import logoLidi from "../images/logoLidi.png";
import logoFCEUNLP from "../images/logoFCEUNLP.png";
import { Col, Row } from "react-bootstrap";

function Footer() {
  return (
    <footer style={{ marginTop: "1.5rem" }}>
      <div className="footBar">
        <Row>
          <Col>
            <a href="http://sedici.unlp.edu.ar/">
              <img className="footerImg" src={logoSedici} alt="sedici logo" />
            </a>
          </Col>
          <Col>
            <a href="http://weblidi.info.unlp.edu.ar/wp/">
              <img className="footerImg" src={logoLidi} alt="lidi logo" />
            </a>
          </Col>
          <Col>
            <a href="https://www.econo.unlp.edu.ar/">
              <img
                className="footerImg wider"
                src={logoFCEUNLP}
                alt="FCE logo"
              />
            </a>
          </Col>
        </Row>
      </div>
    </footer>
  );
}
export default Footer;
