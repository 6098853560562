import orcidLogo from "../../../images/orcid.png";

function Authors({ authors }) {
  const size = authors.length;

  return (
    <>
      <div>
        {size === 0 ? null : size === 1 ? (
          <span>
            <b>Autor:</b>
          </span>
        ) : (
          <span>
            <b>Autores:</b>
          </span>
        )}

        <ul>
          {authors.map((author) => (
            <li key={author.id}>
              <span>
                <b>{author.name + " "}</b>
              </span>
              {author.orcid && (
                <a href={author.orcid} target="_blank" rel="noreferrer">
                  <img width="23em" src={orcidLogo} alt="orcid Logo" />
                </a>
              )}
              {author.institution && (
                <div style={{ marginTop: "-0.4em" }}>
                  <i style={{ fontSize: "80%", color: "grey" }}>
                    <em>
                      Institución:{" "}
                      <a
                        style={{ color: "#ffaaaa" }}
                        href={author.institution.ror}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {author.institution.name}
                      </a>
                    </em>
                  </i>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Authors;
