// config.js
let globalConfig = null;

export const setGlobalConfig = (config) => {
  globalConfig = config;
};

export const getGlobalConfig = () => {
  return globalConfig;
};

export const getApiConfig = (config, apiType, apiObjectName) => {
  if (!config) return null;
  return config.APIS_CONFIG[apiType].find(
    (obj) => obj.Object === apiObjectName,
  );
};
