import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import { validDOI, validISSN } from "../helpers/regex";
import { useSelector, useDispatch } from "react-redux";
import { setContent } from "../slicers/contentSlicer";
import {
  setQuery,
  setCriteria,
  setLoadStage,
  setFormatError,
} from "../slicers/searchSlicer";
import { exportCsvData } from "../api-front/dataBuilder";
import { Card } from "react-bootstrap";
import { useState } from "react";
import { BsFiletypeJson, BsFiletypeCsv } from "react-icons/bs";

function ExportDataPage() {
  const query = useSelector((state) => state.search.query);
  const criteria = useSelector((state) => state.search.criteria);
  const dispatch = useDispatch();
  const [exportCriteria, setExportCriteria] = useState(undefined);

  const radios = [
    { name: "DOI", value: "DOI" },
    { name: "ISSN", value: "ISSN" },
  ];

  const searchPapers = async (e) => {
    e.preventDefault();
    if (criteria === "DOI") {
      dispatch(setLoadStage(1));
    } else {
      dispatch(setLoadStage(2));
    }
    const res = await exportCsvData(query, criteria);
    if (exportCriteria === "CSV") {
      const csvData = new Blob([res], { type: "text/csv;charset=utf-8;" });
      const csvUrl = URL.createObjectURL(csvData);
      const tempLink = document.createElement("a");
      tempLink.href = csvUrl;
      tempLink.setAttribute("download", "data.csv");
      tempLink.click();
    } else {
      const jsonData = JSON.stringify(res, null, 2);
      const jsonBlob = new Blob([jsonData], {
        type: "application/json;charset=utf-8;",
      });
      const jsonUrl = URL.createObjectURL(jsonBlob);
      const tempLink = document.createElement("a");
      tempLink.href = jsonUrl;
      tempLink.setAttribute("download", "data.json");
      tempLink.click();
    }

    dispatch(setFormatError(false));
    dispatch(setLoadStage(0));
  };

  const validateItems = (items, validator) => {
    for (let item of items) {
      if (!validator.test(item)) {
        return false;
      }
    }
    return true;
  };

  const validateQuery = async (e) => {
    e.preventDefault();
    console.log(exportCriteria);
    const stringWithoutSpaces = query.replace(/\s/g, "");

    const items = stringWithoutSpaces.split(",");

    if (criteria === "DOI") {
      if (validateItems(items, validDOI)) {
        searchPapers(e);
      } else {
        dispatch(setFormatError(true));
      }
    } else if (criteria === "ISSN") {
      if (validateItems(items, validISSN)) {
        dispatch(searchPapers(e));
      } else {
        dispatch(setFormatError(true));
      }
    }
  };

  return (
    <>
      <Card style={{ marginTop: "1rem" }}>
        <Card.Header>Exportar información</Card.Header>
        <Card.Body>
          <h4 style={{ color: "#FF9A9A" }}>¿Como exportar información?</h4>
          <p>
            Ingrese los recursos que desea exportar en el buscador, asegurándose
            de buscar solo DOIs o ISSNs, pero no ambos al mismo tiempo. Después
            de completar la información, haga clic en "Exportar CSV" o "Exportar
            JSON", según su preferencia.
          </p>
          <p> Solamente se pueden ingresar 100 recursos.</p>
          <Form onSubmit={validateQuery} style={{ marginTop: "1rem" }}>
            <Row className="justify-content-md-center">
              <Col md={9}>
                <InputGroup>
                  <Form.Control
                    as="textarea"
                    placeholder={
                      criteria === "DOI"
                        ? "Ejemplo: 10.1000/xyz123,10.1000/xyz124,10.1000/xyz125,10.1000/xyz126"
                        : criteria === "ISSN"
                        ? "Ejemplo: 2049-3630,2049-3631,2049-3632,2049-3633"
                        : "Seleccione DOI o ISSN"
                    }
                    aria-label="Buscar"
                    onChange={(e) => dispatch(setQuery(e.target.value))}
                  />
                  <ButtonGroup>
                    <InputGroup>
                      {radios.map((radio, idx) => (
                        <ToggleButton
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          variant="outline-secondary"
                          name="radio"
                          value={radio.value}
                          checked={criteria === radio.value}
                          onChange={(e) => {
                            dispatch(setContent(undefined));
                            dispatch(setCriteria(e.currentTarget.value));
                          }}
                        >
                          <div className="d-flex align-items-center">
                            {radio.name}
                          </div>
                        </ToggleButton>
                      ))}
                    </InputGroup>
                  </ButtonGroup>
                </InputGroup>
              </Col>
              <Col>
                <Button
                  type="submit"
                  variant="outline-success"
                  className="me-2"
                  onClick={(e) => setExportCriteria("CSV")}
                  disabled={
                    criteria !== "DOI" && criteria !== "ISSN" ? true : false
                  }
                >
                  Exportar <BsFiletypeCsv style={{ scale: "1.5" }} />
                </Button>

                <Button
                  type="submit"
                  variant="outline-warning"
                  onClick={(e) => setExportCriteria("JSON")}
                  disabled={
                    criteria !== "DOI" && criteria !== "ISSN" ? true : false
                  }
                >
                  Exportar <BsFiletypeJson style={{ scale: "1.5" }} />
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
export default ExportDataPage;
