import { useSelector } from "react-redux";
import { Row, Card, Col, Spinner, Button, Alert } from "react-bootstrap";
import Authors from "./Authors";
import DetailsCard from "./DetailsCard";
import ResumeCard from "./ResumeCard";
import { Collapse } from "react-collapse";
import { useState } from "react";
import { MdExpandMore, MdExpandLess, MdErrorOutline } from "react-icons/md";

function CardDOI({ content, isFirst }) {
  const findISSNdata = useSelector((state) => state.search.loadStage);

  const [showMore, setShowMore] = useState(false);
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const [showCard, setshowCard] = useState(isFirst ? false : true);
  const handleShowCard = () => {
    setshowCard(!showCard);
  };

  const notFound = content.OpenAlexDOI.notFound && content.CrossrefDOI.notFound;

  return (
    <>
      {!notFound ? (
        <Card style={{ marginTop: "1rem" }}>
          <Card.Header onClick={handleShowCard}>
            <Row>
              <Col>
                <i style={{ color: "grey" }}>DOI /</i>{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`https://dx.doi.org/${content.doi}`}
                >
                  {content.doi}
                </a>
              </Col>
              <Col>
                {showCard === false ? (
                  <MdExpandLess
                    style={{ scale: "2" }}
                    onClick={handleShowCard}
                    className="float-end"
                  />
                ) : (
                  <MdExpandMore
                    style={{ scale: "2" }}
                    onClick={handleShowCard}
                    className="float-end"
                  />
                )}
              </Col>
            </Row>
          </Card.Header>

          <Collapse
            isOpened={!showCard}
            theme={{ collapse: "ReactCollapse--collapse" }}
            key={"collapseCard"}
          >
            <Card.Title className="text-center" style={{}}>
              <h1 className="display-6">{content.title}</h1>
              <hr></hr>{" "}
            </Card.Title>
            <Card.Body>
              <Row>
                <Col xs={12} sm={7}>
                  <Authors authors={content.authors} />
                </Col>
                <Col style={{ alignItems: "center" }}>
                  <p>
                    {content.publication_year && (
                      <>
                        <b>Año de publicación:</b>{" "}
                        {" " + content.publication_year}
                      </>
                    )}
                  </p>
                  {findISSNdata === 2 ? (
                    <>
                      <p>
                        <b>Título de la revista: </b>
                        <Spinner animation="border" size="sm" />
                      </p>
                      <p>
                        <b>Editorial: </b>{" "}
                        <Spinner animation="border" size="sm" />
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        {content.journalTitle && (
                          <>
                            <b>Título de la revista:</b>{" "}
                            {" " + content.journalTitle}
                          </>
                        )}
                      </p>
                      <p>
                        {content.publisher && (
                          <>
                            <b>Editorial:</b> {" " + content.publisher}
                          </>
                        )}
                      </p>
                    </>
                  )}

                  <p>
                    {content.type && (
                      <>
                        <b>Tipo de recurso:</b> {" " + content.type}
                      </>
                    )}
                  </p>

                  {content.issnData ? null : (
                    <>
                      <p>
                        <b>Evento/Libro:</b>{" "}
                        {content.venue_name && content.venue_name + " "}
                        {content.venue_type
                          ? "(" + content.venue_type + ")"
                          : ""}
                      </p>
                    </>
                  )}
                </Col>
              </Row>
              <div style={{ textAlign: "justify" }}>
                <p>
                  {content.abstract && (
                    <>
                      <b>Resumen:</b> <br></br> {" " + content.abstract}
                    </>
                  )}
                </p>
              </div>

              <Collapse
                isOpened={!showMore}
                theme={{ collapse: "ReactCollapse--collapse" }}
                key={"collapse1"}
              >
                <ResumeCard content={content} />
              </Collapse>
              <div className="d-grid gap-2">
                {showMore === false ? (
                  <Button onClick={handleShowMore}>
                    Ver más <MdExpandMore />
                  </Button>
                ) : (
                  <Button onClick={handleShowMore}>
                    Ver menos <MdExpandLess />
                  </Button>
                )}
              </div>
              <Collapse
                isOpened={showMore}
                theme={{ collapse: "ReactCollapse--collapse" }}
                key={"collapse2"}
              >
                <DetailsCard content={content} />
              </Collapse>
            </Card.Body>
          </Collapse>
        </Card>
      ) : (
        <Alert
          className="text-center"
          style={{ marginTop: "1rem" }}
          key={content.doi}
          variant="danger"
        >
          No se encontró informacion para el DOI: <b>{content.doi} </b>
        </Alert>
      )}
    </>
  );
}

export default CardDOI;
