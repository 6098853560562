import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import { svgSealDOAJ } from "../../contants";
import doajLogo from "../../../../../images/resize/DOAJ-logo-v1.png";

import { MdAttribution } from "react-icons/md";
import { AiOutlineCopyright } from "react-icons/ai";
import { TbNoDerivatives } from "react-icons/tb";
import { MdMoneyOff } from "react-icons/md";
import { GiRapidshareArrow } from "react-icons/gi";

const renderLicense = (index, licenseType) => {
  switch (licenseType) {
    case "CC BY":
      return (
        <div className="text-center" key={index}>
          <a href="https://creativecommons.org/licenses/by/4.0/">
            <p style={{ fontSize: "2rem", color: "white" }}>
              {" "}
              <AiOutlineCopyright />
              <MdAttribution />
            </p>
          </a>
        </div>
      );
    case "CC BY-ND":
      return (
        <div className="text-center" key={index}>
          <a href="https://creativecommons.org/licenses/by-nd/4.0/">
            <p style={{ fontSize: "2rem", color: "white" }}>
              {" "}
              <AiOutlineCopyright />
              <MdAttribution />
              <TbNoDerivatives />
            </p>
          </a>
        </div>
      );
    case "CC BY-NC":
      return (
        <div className="text-center" key={index}>
          <a href="https://creativecommons.org/licenses/by-nc/4.0/">
            <p style={{ fontSize: "2rem", color: "white" }}>
              {" "}
              <AiOutlineCopyright />
              <MdAttribution />
              <MdMoneyOff />
            </p>
          </a>
        </div>
      );
    case "CC BY-SA":
      return (
        <div className="text-center" key={index}>
          <a href="https://creativecommons.org/licenses/by-sa/4.0/">
            <p style={{ fontSize: "2rem", color: "white" }}>
              {" "}
              <AiOutlineCopyright />
              <MdAttribution />
              <GiRapidshareArrow />
            </p>
          </a>
        </div>
      );
    case "CC BY-NC-ND":
      return (
        <div className="text-center" key={index}>
          <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/">
            <p style={{ fontSize: "2rem", color: "white" }}>
              {" "}
              <AiOutlineCopyright />
              <MdAttribution />
              <MdMoneyOff />
              <TbNoDerivatives />
            </p>
          </a>
        </div>
      );
    case "CC BY-NC-SA":
      return (
        <div className="text-center" key={index}>
          <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/">
            <p style={{ fontSize: "2rem", color: "white" }}>
              {" "}
              <AiOutlineCopyright />
              <MdAttribution />
              <MdMoneyOff />
              <GiRapidshareArrow />
            </p>
          </a>
        </div>
      );
    default:
      return null;
  }
};

function DoajIssnResume({ content, issnValue }) {
  //const content = useSelector(state => state.content.value.issnData.DoajISSN)
  //const issnValue = content.identifier.value

  return (
    <Card
      className="text-center bg-doaj-gradient"
      style={{ alignItems: "center" }}
      text="white"
    >
      {/* <a
                style={{ marginTop: '1rem', width: '12rem', color: 'black' }}
                target='_blank'
                rel="noreferrer"
                href={`https://doaj.org/toc/${issnValue}`}>
                {svgLogoDOAJ}
            </a> */}
      <a href={content.link} target="_blank" rel="noreferrer">
        <Card.Img
          variant="top"
          alt="CrossRef Logo"
          style={{ marginTop: "1rem", width: "100%" }}
          src={doajLogo}
        />
      </a>

      <Card.Body>
        {content.notFound ? (
          <p style={{ fontSize: "20px", marginTop: "10px" }}>
            Recurso no encontrado
          </p>
        ) : (
          <>
            <Card.Title style={{ marginTop: "1rem" }}>
              {content.seal ? (
                <Button variant="light" href="https://doaj.org/apply/seal">
                  {svgSealDOAJ}
                </Button>
              ) : null}
            </Card.Title>
            <Card.Text>
              {content.license
                ? content.license.map((license, index) =>
                    renderLicense(index, license.type),
                  )
                : null}
            </Card.Text>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default DoajIssnResume;
