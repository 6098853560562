import Card from "react-bootstrap/Card";
import { useAltmetric } from "../../../../../hooks/useScript";
import altmetricLogo from "../../../../../images/altmetricLogo.png";
import hotTopic from "../../../../../images/hotTopic.png";
import chatLogo from "../../../../../images/chatLogo.png";
import redCross from "../../../../../images/redCross.png";

function AltmetricDoiResume({ content }) {
  //const content = useSelector(state => state.content.value.Altmetric)

  useAltmetric("https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js");

  return (
    <>
      <Card
        className="text-center bg-altmetric-gradient"
        style={{ alignItems: "center" }}
      >
        <Card.Body>
          <Card.Text>
            {content.error ? (
              <>
                <div>
                  <Card.Img
                    variant="top"
                    style={{ width: "5rem" }}
                    src={altmetricLogo}
                  />
                  <br />
                  <img
                    src={redCross}
                    style={{ width: "2em" }}
                    alt="not_found"
                  />
                  <Card.Title style={{ marginTop: "1rem" }}>
                    Altmetric
                  </Card.Title>

                  <p style={{ fontSize: "20px" }}>Recurso no encontrado</p>
                </div>
              </>
            ) : (
              <div
                className="altmetric-embed"
                data-badge-type="medium-donut"
                data-doi={content.doi}
                data-badge-popover="right"
              ></div>
            )}
            {!content.error ? (
              <Card.Title style={{ marginTop: "1rem" }}>Altmetric</Card.Title>
            ) : null}
            {content.cited_by_posts_count ? (
              content.cited_by_posts_count > 100 ? (
                <div>
                  Hot topic en redes sociales!
                  <br />
                  <a href="https://www.altmetric.com">
                    <img
                      style={{ width: "2em" }}
                      src={hotTopic}
                      alt="hot_topic"
                    />
                  </a>
                </div>
              ) : (
                <div>
                  Se habla del tema
                  <br />
                  <a href="https://www.altmetric.com">
                    <img
                      style={{ width: "2em" }}
                      src={chatLogo}
                      alt="people_chat"
                    />
                  </a>
                </div>
              )
            ) : null}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

export default AltmetricDoiResume;
