import Card from "react-bootstrap/Card";
import crossrefLogo from "../../../../../images/resize/crossref-logo.png";

function CrossrefIssnResume({ content }) {
  //const content = useSelector(state => state.content.value.OpenAlexDOI)

  return (
    <Card
      className="text-center bg-crossref-gradient "
      style={{ alignItems: "center" }}
    >
      <a target="_blank" rel="noreferrer" href={content.url}>
        <Card.Img
          variant="top"
          alt="CrossRef Logo"
          style={{ marginTop: "1rem", width: "100%" }}
          src={crossrefLogo}
        />
      </a>
      <Card.Body>
        <Card.Title>
          {!content.notFound ? (
            <p>
              <span style={{ fontSize: "1.2rem" }}>Artículos:</span> <br></br>
              <b style={{ fontSize: "2.5rem" }}> {content.total_dois}</b>
            </p>
          ) : (
            <p>Recurso no encontrado</p>
          )}
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

export default CrossrefIssnResume;
