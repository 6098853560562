import Card from "react-bootstrap/Card";
import wosLogo from "../../../../../images/resize/WoS-logo-v2.png";
import { BsCheck2Circle } from "react-icons/bs";

function WosIssnResume({ content }) {
  //const content = useSelector(state => state.content.value.issnData.WosISSN)
  // console.log(content)

  return (
    <Card
      className="text-center bg-wos-gradient"
      style={{ alignItems: "center" }}
      bg="light"
      text="black"
    >
      <a
        href={`https://mjl.clarivate.com/search-results?issn=${content.issn}&hide_exact_match_fl=true`}
        target="_blank"
        rel="noreferrer"
      >
        <Card.Img
          variant="top"
          alt="Web of Science"
          style={{ marginTop: "1.5rem", width: "100%" }}
          src={wosLogo}
        />
      </a>
      <Card.Body>
        <Card.Text>
          {!content.notFound ? (
            <p
              style={{
                color: "white",
                fontSize: "1.8rem",
                marginTop: "3rem",
                fontWeight: "bold",
              }}
            >
              Indexado <BsCheck2Circle />
            </p>
          ) : (
            <p style={{ color: "white", fontSize: "20px", marginTop: "57px" }}>
              Recurso no encontrado
            </p>
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default WosIssnResume;
