import Card from "react-bootstrap/Card";
import openAlexlogo from "../../../../../images/resize/OpenAlex-logo.png";

function OpenAlexIssnResume({ content }) {
  //const content = useSelector(state => state.content.value.issnData.OpenAlexISSN)
  // console.log(content)
  const id = content?.id ?? null;

  return (
    <Card
      className="text-center bg-openalex-gradient"
      style={{ alignItems: "center" }}
      bg="secondary"
      text="white"
    >
      <a href={id} target="_blank" rel="noreferrer">
        <Card.Img
          variant="top"
          alt="Open Alex Logo"
          style={{ marginTop: "1rem", width: "100%" }}
          src={openAlexlogo}
        />
      </a>
      <Card.Body>
        <Card.Title>
          {content && content.notFound ? (
            <p>{content.error}</p>
          ) : (
            <p>
              <span style={{ fontSize: "1.2rem" }}>Artículos:</span> <br></br>
              <b style={{ fontSize: "2.5rem" }}>
                {" "}
                {content?.works_count ?? null}
              </b>
            </p>
          )}
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

export default OpenAlexIssnResume;
