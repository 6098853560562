import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import redibLogo from "../../../../../images/resize/redib-logo.png";

function RedibIssnDetails({ content, issnValue }) {
  return (
    <>
      <Card
        className="tab-color-redib-detail"
        style={{ marginTop: "2rem", height: "100%" }}
        bg="light"
        text="black"
      >
        <Card.Body>
          <Row>
            <Col xs={12} sm={4}>
              <a
                href={`https://redib.org/Search/Results?type=ISN&lookfor=${issnValue}`}
                target="_blank"
                rel="noreferrer"
              >
                <Card.Img
                  variant="top"
                  alt="REDIB"
                  style={{ width: "6rem" }}
                  src={redibLogo}
                />
              </a>
            </Col>

            <Col>
              {/* Actualmente fuera de servicio, sin una api o web para hacerle scraping */}
              <p className="text-center">Servidor no disponible</p>
            </Col>

            {/* {!content.notFound ?
                        content.widget ?
                            <Col>
                                <a href={content.widget.anchorHref}>
                                    <img border="0" width="125px" height="125px" src={content.widget.imgSrc} alt="redib_rank_widget" />
                                </a>
                            </Col>

                            :
                            <>
                                <Col>
                                    <a href={content.redibURL}><img className="card--tiny--image big" src={redibLogo} alt="redib_logo" /></a>
                                    <div style={{ fontFamily: 'Roboto', padding: "1.5rem" }} className="card--tiny--info--data">Indicadores de calidad editorial</div>
                                    {content?.indicadores?.map(indicador =>
                                        <div key={indicador} style={{ fontFamily: 'Roboto' }} >
                                            {indicador}
                                        </div>
                                    )}
                                </Col>

                            </>

                        :
                        <Col>
                            <p className='text-center'>{content.error}</p></Col>} */}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default RedibIssnDetails;
