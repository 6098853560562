import CardGroup from "react-bootstrap/CardGroup";
import OpenAlexDoiResume from "./DOI/OpenAlexDoiResume";
import DoajDoiResume from "./DOI/DoajDoiResume";
import SemanticScholarDoiResume from "./DOI/SemanticScholarDoiResume";
import DimensionsDoiResume from "./DOI/DimensionsDoiResume";
import AlmetricDoiResume from "./DOI/AltmetricDoiResume";
import GoogleScholarResume from "./DOI/GoogleScholarResume";
import { useSelector } from "react-redux";
//import { openAlexDOIConfig, doajDOIConfig, semanticScholarConfig, dimensionsConfig, altmetricConfig,crossrefDOIConfig } from '../../../../helpers/getConfig';
import CrossrefDoiResume from "./DOI/CrossrefDoiResume";
import { getGlobalConfig, getApiConfig } from "../../../../config";
import ScopusDoiResume from "./DOI/ScopusDoiResume";

function ListCardDoiResume({ content }) {
  const config = getGlobalConfig();

  const criteria = useSelector((state) => state.search.criteria);
  // console.log(content)    // console.log('LIST DOI')
  const openAlexDOIConfig = getApiConfig(config, "DOI", "OpenAlexDOI");
  const doajDOIConfig = getApiConfig(config, "DOI", "DoajDOI");
  const semanticScholarConfig = getApiConfig(config, "DOI", "SemanticScholar");
  const dimensionsConfig = getApiConfig(config, "DOI", "Dimensions");
  const altmetricConfig = getApiConfig(config, "DOI", "Altmetric");
  const crossrefDOIConfig = getApiConfig(config, "DOI", "CrossrefDOI");
  const ScopusDOIConfig = getApiConfig(config, "DOI", "ScopusDOI");

  return (
    <>
      {criteria === "DOI" ? (
        <CardGroup style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          {crossrefDOIConfig.enabled ? (
            <CrossrefDoiResume content={content.CrossrefDOI} />
          ) : null}
          {openAlexDOIConfig.enabled ? (
            <OpenAlexDoiResume content={content.OpenAlexDOI} />
          ) : null}
          {doajDOIConfig.enabled ? (
            <DoajDoiResume content={content.DoajDOI} />
          ) : null}
          {semanticScholarConfig.enabled ? (
            <SemanticScholarDoiResume content={content.SemanticScholar} />
          ) : null}
          <GoogleScholarResume title={content.title} />
          {ScopusDOIConfig.enabled ? (
            <ScopusDoiResume content={content.ScopusDOI} />
          ) : null}
          {dimensionsConfig.enabled ? (
            <DimensionsDoiResume content={content.Dimensions} />
          ) : null}
          {altmetricConfig.enabled ? (
            <AlmetricDoiResume content={content.Altmetric} />
          ) : null}
        </CardGroup>
      ) : null}
    </>
  );
}

export default ListCardDoiResume;
