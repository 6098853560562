import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import googleLogo from "../../../../../images/resize/google-logo.png";


function GoogleScholarResume({ title }) {

  return (
    <>
      <Card
        className="text-center bg-crossref-gradient "
        style={{ alignItems: "center" }}
      >
        <Card.Img
          variant="top"
          alt="CrossRef Logo"
          style={{ marginTop: "1rem", width: "8rem" }}
          src={googleLogo}
        />

        <Card.Body>
          <Card.Title>
            <Button
              variant="outline-dark"
              target="_blank"
              href={`https://scholar.google.com/scholar?q=allintitle:${title}`}
            >
              Buscar citas en Google Scholar{" "}
            </Button>
            {/* <FcGoogle style={{scale :'1.2'}}/> */}
          </Card.Title>
        </Card.Body>
      </Card>
    </>
  );
}

export default GoogleScholarResume;
