import Card from "react-bootstrap/Card";
import dimensionsLogo from "../../../../../images/dimensionsLogo.png";
import { useDimensions } from "../../../../../hooks/useScript";

function DimensionsDoiResume({ content }) {
  //const content = useSelector(state => state.content.value.Dimensions)
  useDimensions("https://badge.dimensions.ai/badge.js");

  return (
    <Card
      className="text-center bg-dimensions-gradient"
      style={{ alignItems: "center" }}
    >
      {content.doi ? (
        <span
          style={{ marginTop: "1rem" }}
          className="__dimensions_badge_embed__"
          data-doi={content.doi}
          data-legend="hover-bottom"
        />
      ) : (
        <>
          Sin información
          <Card.Img
            variant="top"
            style={{ width: "95%" }}
            src={dimensionsLogo}
          />
        </>
      )}
      <Card.Body>
        <Card.Title>Dimensions</Card.Title>
      </Card.Body>
    </Card>
  );
}

export default DimensionsDoiResume;
