// features/config/configSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    storeConfig: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { storeConfig } = configSlice.actions;
export default configSlice.reducer;
