import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import ListCardsDoiDetails from "./listCardsDetails/ListCardsDoiDetails";
import ListCardsIssnDetails from "./listCardsDetails/ListCardsIssnDetails";

function DetailsCard({ content }) {
  const criteria = useSelector((state) => state.search.criteria);

  return (
    <>
      {criteria === "DOI" ? <ListCardsDoiDetails content={content} /> : null}
      {criteria === "ISSN" || content.issnData ? (
        <ListCardsIssnDetails content={content} />
      ) : (
        <Alert
          className="text-center"
          style={{ marginTop: "3rem" }}
          key={content.doi}
          variant="danger"
        >
          No se encontró informacion asociada del ISSN para el DOI:{" "}
          <b>{content.doi}</b>
        </Alert>
      )}
    </>
  );
}

export default DetailsCard;
