import Card from "react-bootstrap/Card";
import { Button, Col, Row } from "react-bootstrap";
import googleLogo from "../../../../../images/googleLogo.png";

function GoogleScholarDoiDetails({ title }) {
  return (
    <Card
      className="tab-color-crossref-detail"
      bg="light"
      style={{ marginTop: "2rem", height: "100%" }}
    >
      <Card.Body>
        <Row>
          <Col xs={12} sm={4}>
            {" "}
            <Card.Img
              variant="top"
              style={{ width: "7rem" }}
              src={googleLogo}
            />{" "}
          </Col>

          <Col>
            <Button
              variant="outline-dark"
              target="_blank"
              href={`https://scholar.google.com/scholar?q=allintitle:${title}`}
            >
              Buscar citas en Google Scholar{" "}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default GoogleScholarDoiDetails;
