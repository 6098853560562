import Card from "react-bootstrap/Card";
import scopusLogo from "../../../../../images/resize/scopus-logo.png";
import { Button } from "react-bootstrap";

function ScopusDoiResume({ content }) {
  //const content = useSelector(state => state.content.value.issnData.ScopusIssn)
  // console.log(content)

  return (
    <Card
      className="text-center bg-scopus-gradient"
      style={{ alignItems: "center" }}
      text="black"
    >
      <a
        href={content.link ?? `https://www.scopus.com/home.uri`}
        target="_blank"
        rel="noreferrer"
      >
        <Card.Img
          variant="top"
          alt="Scopus"
          style={{ marginTop: "1rem", width: "100%" }}
          src={scopusLogo}
        />
      </a>
      <Card.Body>
        <Card.Title> </Card.Title>
        <Card.Text>
          {!content.notFound ? (
            <>
              <Button
                id="scopus-citedby"
                variant="outline-success"
                target="_blank"
                href={content.link}
              >
                {" "}
                Buscar citas en Scopus{" "}
              </Button>
            </>
          ) : (
            // <p>{content.error}</p>}
            <p>Recurso no encontrado</p>
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default ScopusDoiResume;
