export const getDataByQuery = async (query, criteria) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/getData`,
      {
        method: "POST",
        body: new URLSearchParams({
          query: query,
          criteria: criteria,
        }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );
    let contents = await res.json();
    return contents;
  } catch (e) {
    console.error(e);
  }
};

export const exportCsvData = async (query, criteria) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/getCsvData`,
      {
        method: "POST",
        body: new URLSearchParams({
          query: query,
          criteria: criteria,
        }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );
    let contents = await res.json();

    return contents;
  } catch (e) {
    console.error(e);
  }
};
