import { Row, Card, Button, Col, Alert } from "react-bootstrap";
import { Collapse } from "react-collapse";
import DetailsCard from "./DetailsCard";
import ResumeCard from "./ResumeCard";
import { useState } from "react";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

function CardISSN({ content, isFirst }) {
  const [showCard, setshowCard] = useState(isFirst ? false : true);
  const handleShowCard = () => {
    setshowCard(!showCard);
  };
  const [showMore, setShowMore] = useState(false);
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const notFound =
    content.OpenAlexISSN.notFound && content.CrossrefISSN.notFound;

  return (
    <>
      {!notFound ? (
        <Card style={{ marginTop: "1rem" }}>
          <Card.Header onClick={handleShowCard}>
            <Row>
              <Col>
                <i style={{ color: "grey" }}>ISSN /</i>{" "}
                <a rel="noreferrer" target="_blank" href={content.URL}>
                  {content.identifier.value}
                </a>
              </Col>
              <Col>
                {showCard === false ? (
                  <MdExpandLess
                    style={{ scale: "2" }}
                    onClick={handleShowCard}
                    className="float-end"
                  />
                ) : (
                  <MdExpandMore
                    style={{ scale: "2" }}
                    onClick={handleShowCard}
                    className="float-end"
                  />
                )}
              </Col>
            </Row>
          </Card.Header>

          <Collapse
            isOpened={!showCard}
            theme={{ collapse: "ReactCollapse--collapse" }}
            key={"collapseCard"}
          >
            <Card.Title className="text-center" style={{}}>
              <h1 className="display-6">{content.title}</h1>
              <hr></hr>{" "}
            </Card.Title>
            <Card.Body>
              <Collapse
                isOpened={!showMore}
                theme={{ collapse: "ReactCollapse--collapse" }}
                key={"collapse1"}
              >
                <ResumeCard content={content} />
              </Collapse>
              <div className="d-grid gap-2">
                {showMore === false ? (
                  <Button onClick={handleShowMore}>
                    Ver más <MdExpandMore />
                  </Button>
                ) : (
                  <Button onClick={handleShowMore}>
                    Ver menos <MdExpandLess />
                  </Button>
                )}
              </div>
              <Collapse
                isOpened={showMore}
                theme={{ collapse: "ReactCollapse--collapse" }}
                key={"collapse2"}
              >
                <DetailsCard content={content} />
              </Collapse>
            </Card.Body>
          </Collapse>
        </Card>
      ) : (
        <Alert
          className="text-center"
          style={{ marginTop: "1rem" }}
          key={content.identifier.value}
          variant="danger"
        >
          No se encontró informacion para el ISSN:{" "}
          <b>{content.identifier.value} </b>
        </Alert>
      )}
    </>
  );
}

export default CardISSN;
