import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { BsFillArrowUpLeftCircleFill } from "react-icons/bs";

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const checkScrollPosition = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollPosition);

    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {showButton && (
        <BsFillArrowUpLeftCircleFill
          className="back-to-top"
          variant="primary"
          onClick={scrollToTop}
        />
      )}
    </>
  );
};

export default BackToTopButton;
