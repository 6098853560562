import { Card, Col, Row } from "react-bootstrap";
import newheralogo from "../images/newheralogo.png";

function AboutUs() {
  return (
    <Card style={{ marginTop: "1rem" }}>
      <Card.Header>Sobre HERA</Card.Header>
      <Card.Body>
        <article>
          <section>
            <p>
              En la actualidad, los miembros de la comunidad académica y
              científica se enfrentan a una tarea ardua y engorrosa cuando deben
              determinar la calidad y el impacto de una publicación científica.
              Esta situación se debe a dos factores. En primer lugar, al
              crecimiento exponencial en la disponibilidad de recursos que tuvo
              el área debido al desarrollo tecnológico. En segundo lugar, la
              ausencia de estándares y la consecuente existencia de múltiples
              métricas y sistemas de evaluación que, aunque comparten objetivos,
              no siempre lo llevan a cabo de la misma manera.
            </p>
          </section>

          <section>
            <p>
              <em>
                La Herramienta para Enriquecimiento de Recursos Académicos
                (HERA)
              </em>{" "}
              es una propuesta que busca dar respuesta al problema anterior y
              surge como una iniciativa conjunta entre diferentes unidades de la
              UNLP: la&nbsp;
              <a
                style={{ color: "#FF9A9A" }}
                href="https://www.econo.unlp.edu.ar/investigacion_transferencia"
              >
                Secretaría de Investigación y Transferencia
              </a>{" "}
              de la Facultad de Ciencias Económicas, el&nbsp;
              <a
                style={{ color: "#FF9A9A" }}
                href="http://weblidi.info.unlp.edu.ar/wp/"
              >
                III-LIDI
              </a>{" "}
              de la Facultad de Informática y el&nbsp;
              <a style={{ color: "#FF9A9A" }} href="http://sedici.unlp.edu.ar/">
                SEDICI
              </a>
              . <em>HERA </em>apunta a simplificar, agilizar y apoyar el proceso
              de determinar la calidad y el impacto de un recurso académico.
              Para ello integra información proveniente de diferentes bases de
              datos académicas en forma rápida y concisa.
            </p>
          </section>

          <section>
            <p>
              Producción Científica-Tecnológica y Académica:
              <ul style={{ marginTop: "0.5rem", fontSize: "0.92rem" }}>
                <li>
                  Porto, J. F., Rucci, E., & Villarreal, G. L. (2023). HERA, una
                  Herramienta para la Evaluación de Recursos Académicos. En
                  XXVIII Congreso Argentino de Ciencias de la Computación
                  (CACIC)(La Rioja, 3 al 6 de octubre de 2022). <a style={{ color: "#FF9A9A" }}  href="http://sedici.unlp.edu.ar/handle/10915/149623" target="_blank" rel="noopener noreferrer">Link</a>
                </li>
                <li>
                  Porto, J. F., Rucci, E., & Villarreal, G. L. (2022).
                  HERA-Herramienta para Enriquecimiento de Recursos Académicos.
                  En XI Conferencia Internacional de Bibliotecas y Repositorios
                  Digitales (BIREDIAL-ISTEC)(Modalidad virtual, 3 al 7 de
                  octubre de 2022). <a style={{ color: "#FF9A9A" }} href="https://submissions.istec.org/index.php/biredial-istec/article/view/174" target="_blank" rel="noopener noreferrer">Link</a> 
                </li>
                <li>
                  “HERA: Herramienta para Enriquecimiento de Recursos
                  Académicos”. Registro de propiedad intelectual número:
                  RL-2022-29343788-APN-DNDA#MJ”. Autores: Juan Francisco Porto,
                  Enzo Rucci, Gonzalo Luján Villarreal, Ana Clara Rucci, Hugo
                  Armando Collacciani. Titular: Instituto de Investigación en
                  Informática LIDI (III-LIDI) – UNLP; SEDICI – UNLP; Facultad de
                  Ciencias Económicas – UNLP. Marzo 2022. <a style={{ color: "#FF9A9A" }} href="https://weblidi.info.unlp.edu.ar/publicaciones/registros-de-propiedad-intelectual/" target="_blank" rel="noopener noreferrer">Link</a> 
                </li>
                <li>
                  “HERA: Herramienta para Enriquecimiento de Recursos
                  Académicos”. Juan Francisco Porto. Tesina de Licenciatura en
                  Sistemas, Universidad Nacional de La Plata. Director: Rucci,
                  Enzo. Co-director: Villarreal, Gonzalo. 2021. <a style={{ color: "#FF9A9A" }} href="http://sedici.unlp.edu.ar/handle/10915/129874" target="_blank" rel="noopener noreferrer">Link</a> 
                </li>
                <li>
                “HERA 2.0: Extensión de Alcance y Funcionalidad”. Ezequiel 
                Carletti. Tesina de Licenciatura en Sistemas, Universidad Nacional de 
                La Plata. Director: Rucci, Enzo. Co-director: Villarreal, Gonzalo. 
                2023 <a style={{ color: "#FF9A9A" }} href="http://sedici.unlp.edu.ar/handle/10915/157417" target="_blank" rel="noopener noreferrer">Link</a> 
                </li>
              </ul>
            </p>
          </section>
        </article>
      </Card.Body>
      <Row>
        <Col className="d-flex justify-content-center align-items-center">
          <Card.Img src={newheralogo} style={{ width: "10rem" }} />
        </Col>
      </Row>
    </Card>
  );
}
export default AboutUs;
