import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import wosLogo from "../../../../../images/wosLogo.png";
import Badge from "react-bootstrap/Badge";

function WosIssnDetails({ content }) {
  return (
    <>
      <Card
        className="tab-color-wos-detail"
        style={{ marginTop: "1rem", height: "100%" }}
        bg="light"
        text="black"
      >
        <Card.Body>
          <Row>
            <Col xs={12} sm={4}>
              <a
                href={`https://mjl.clarivate.com/search-results?issn=${content.issn}&hide_exact_match_fl=true`}
                target="_blank"
                rel="noreferrer"
              >
                <Card.Img
                  variant="top"
                  alt="Web of Science"
                  style={{ width: "12rem" }}
                  src={wosLogo}
                />
              </a>
            </Col>
            <Col>
              {!content.notFound ? (
                <>
                  {content.publisherName ? (
                    <p>
                      Editor: <b>{content.publisherName}</b>
                    </p>
                  ) : null}
                  {content.publicationFrequency ? (
                    <p>
                      Periodicidad: <b>{content.publicationFrequency}</b>
                    </p>
                  ) : null}
                  {content.country ? (
                    <p>
                      País: <b>{content.country}</b>
                    </p>
                  ) : null}

                  {content.publicationLanguages ? (
                    <ul>
                      Idiomas:
                      {content.publicationLanguages.map((keyword) => (
                        <li key={keyword}>
                          <b>{keyword}</b>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                  {content.categories && content.categories.length !== 0 ? (
                    <>
                      Colecciones:
                      <br></br>
                      {content.categories.map((item, index) => (
                        <Badge
                          key={`${index}-${item.productCode}`}
                          pill
                          className="bagde-color-wos"
                        >
                          {item.productDescription}
                        </Badge>
                      ))}
                    </>
                  ) : null}
                </>
              ) : (
                <p>Recurso no encontrado</p>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default WosIssnDetails;
