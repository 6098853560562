import Card from "react-bootstrap/Card";
import semanticLogo from "../../../../../images/resize/semanticScholar-white.png";

function SemanticScholarDoiResume({ content }) {
  //const content = useSelector(state => state.content.value.SemanticScholar)
  // console.log(content)

  return (
    <Card
      className="text-center bg-semantic-gradient"
      style={{ alignItems: "center" }}
    >
      {/* <a
        href={content.url}
        target='_blank'
        rel="noreferrer"
        style={{ width: '7rem', marginTop: '1rem' }}
      >
        {svgSemanticLogo}
      </a> */}

      <Card.Body>
        <Card.Title>
          {!content.notFound ? (
            <>
              <a href={content.url} target="_blank" rel="noreferrer">
                <Card.Img
                  variant="top"
                  alt="Semantic Logo"
                  style={{ marginTop: "1rem", width: "100%" }}
                  src={semanticLogo}
                />
              </a>

              <p>
                <span style={{ fontSize: "1.2rem", color: "white" }}>
                  Citas:
                </span>
                <br></br>
                <b style={{ fontSize: "2.5rem", color: "white" }}>
                  {" "}
                  {content.numCitedBy}
                </b>
                <br></br>
                {content.influentialCitationCount > 0 && (
                  <span style={{ color: "#f3d25f" }}>
                    <b style={{ fontSize: "2.5rem" }}>
                      {content.influentialCitationCount}
                    </b>
                    <br></br>
                    influyentes
                  </span>
                )}
              </p>
            </>
          ) : (
            <>
              <a
                href={"https://www.semanticscholar.org/"}
                target="_blank"
                rel="noreferrer"
              >
                <Card.Img
                  variant="top"
                  alt="Semantic Logo"
                  style={{ marginTop: "1rem", width: "100%" }}
                  src={semanticLogo}
                />
              </a>

              <p style={{ color: "white" }}>Recurso no encontrado</p>
            </>
          )}
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

export default SemanticScholarDoiResume;

// const svgLogo = <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="1 0 100 100"><path className="logo__checkmark" d="M110.9 25.5c-3.9 2.5-6.6 3.8-9.9 5.8-19.2 11.6-37.6 24.5-52 41.5l-6.8 8.4L21 47.6c4.7 3.8 16.5 14.3 21.3 16.7l15.5-11.7c10.9-7.6 41.4-24.2 53.1-27.1z"></path><path className="logo__paper-one" d="M37.2 56c1.6 1.3 3.2 2.5 4.5 3.4 3.5-16.9.6-35.2-8.7-51.1 15.6-.2 31.2-.4 46.7-.7 3.5 7.7 5.5 16 6 24.5 1.4-.7 2.7-1.4 4.1-2-.5-8.6-2.9-18-7.7-28.9H18.3C32.2 17.6 38.4 37.4 37.2 56z"></path><path className="logo__paper-two" d="M34.3 53.7c.4.4.9.7 1.3 1.1-.6-15.7-6.5-31.9-17.7-45.7H8c14.3 13.2 23.1 29.1 26.3 44.6z"></path><path className="logo__paper-three" d="m30.9 50.7 1.5 1.2c-4.2-11.7-11.5-23.2-21.9-33.2H0c13.4 9.6 23.7 20.7 30.9 32z"></path></svg>

// const svgSemanticLogo = <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="1 0 100 100"><path style={{ fill: '#f3d25f' }} className="logo__checkmark" d="M110.9,25.5c-3.9,2.5-6.6,3.8-9.9,5.8C81.8,42.9,63.4,55.8,49,72.8l-6.8,8.4L21,47.6 c4.7,3.8,16.5,14.3,21.3,16.7l15.5-11.7C68.7,45,99.2,28.4,110.9,25.5z"></path>
//   <path style={{ fill: 'white' }} className="logo__paper-one" d="M37.2,56c1.6,1.3,3.2,2.5,4.5,3.4c3.5-16.9,0.6-35.2-8.7-51.1c15.6-0.2,31.2-0.4,46.7-0.7 c3.5,7.7,5.5,16,6,24.5c1.4-0.7,2.7-1.4,4.1-2c-0.5-8.6-2.9-18-7.7-28.9c-21.3,0-42.5,0-63.8,0C32.2,17.6,38.4,37.4,37.2,56z"></path>
//   <path style={{ fill: 'white' }} className="logo__paper-two" d="M34.3,53.7c0.4,0.4,0.9,0.7,1.3,1.1C35,39.1,29.1,22.9,17.9,9.1c-3.3,0-6.6,0-9.9,0 C22.3,22.3,31.1,38.2,34.3,53.7z"></path>
//   <path style={{ fill: 'white' }} className="logo__paper-three" d="M30.9,50.7c0.5,0.4,1,0.8,1.5,1.2c-4.2-11.7-11.5-23.2-21.9-33.2c-3.5,0-7,0-10.5,0 C13.4,28.3,23.7,39.4,30.9,50.7z"></path></svg>
