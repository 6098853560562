import Card from "react-bootstrap/Card";
import { Col, Image, Row } from "react-bootstrap";
import { useAltmetric } from "../../../../../hooks/useScript";
import gplusLogo from "../../../../../images/gplusLogo.png";
import fbLogo from "../../../../../images/fbLogo.png";
import twtLogo from "../../../../../images/twtLogo.png";
import redditLogo from "../../../../../images/redditLogo.png";
import altmetricLogo from "../../../../../images/altmetricLogo.png";

function AltmetricDoiDetails({ content }) {
  useAltmetric("https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js");
  const hasCitations =
    content.cited_by_gplus_count ||
    content.cited_by_fbwalls_count ||
    content.cited_by_tweeters_count ||
    undefined;

  return (
    <Card
      className="tab-color-altmetric-detail"
      bg="light"
      style={{ marginTop: "2rem", height: "100%" }}
    >
      <Card.Body>
        {content.notFound ? (
          <Row>
            <Col xs={12} sm={3}>
              {" "}
              <Card.Img
                variant="top"
                style={{ width: "5rem" }}
                src={altmetricLogo}
              />{" "}
            </Col>
            <Col>
              <p>Recurso no encontrado</p>{" "}
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12} sm={3}>
              <div
                className="altmetric-embed"
                data-badge-type="medium-donut"
                data-doi={content.doi}
                data-badge-popover="right"
              ></div>
            </Col>
            <Col>{citations(content, hasCitations)}</Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
}

export default AltmetricDoiDetails;

function citations(content, hasCitations) {
  return (
    <>
      {content.cited_by_posts_count ? (
        <div>
          Este artículo fue mencionado en aproximadamente{" "}
          <b>{content.cited_by_posts_count}</b> posts en la red.
        </div>
      ) : null}
      {hasCitations && (
        <div style={{ fontSize: "12px" }}>
          <br />
          <b>Citado en:</b>
        </div>
      )}
      <div style={{ display: "flex" }}>
        {content.cited_by_fbwalls_count ? (
          <div>
            <Image style={{ width: "2em" }} src={fbLogo} alt="facebook_logo" />
          </div>
        ) : null}
        {content.cited_by_tweeters_count ? (
          <div>
            <Image style={{ width: "2em" }} src={twtLogo} alt="twitter_logo" />
          </div>
        ) : null}
        {content.cited_by_rdts_count ? (
          <div>
            <Image
              style={{ width: "2em" }}
              src={redditLogo}
              alt="reddit_logo"
            />
          </div>
        ) : null}
        {content.cited_by_gplus_count ? (
          <div>
            <Image style={{ width: "2em" }} src={gplusLogo} alt="gplus_logo" />
          </div>
        ) : null}
      </div>
    </>
  );
}
