import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { setFormatError } from "../../slicers/searchSlicer";
import Spinner from "react-bootstrap/Spinner";
import { Alert } from "react-bootstrap";

function LoadingPage() {
  const dispatch = useDispatch();
  const loadStage = useSelector((state) => state.search.loadStage);
  const formatError = useSelector((state) => state.search.formatError);
  const criteria = useSelector((state) => state.search.criteria);

  const loadingMessage =
    loadStage === 1
      ? "Recolectando datos de artículo"
      : loadStage === 2
      ? "Recolectando datos de revista"
      : "";

  useEffect(() => {
    if (formatError) {
      const timer = setTimeout(() => {
        dispatch(setFormatError(false));
      }, 6000); // 6 segundos

      // Limpieza: cancelar el temporizador si el componente se desmonta
      return () => {
        clearTimeout(timer);
      };
    }
  }, [formatError, dispatch]);

  return (
    <>
      <div style={{ marginTop: "1rem" }}>
        {formatError && (
          <Alert
            variant="danger"
            onClose={() => dispatch(setFormatError(false))}
            dismissible
          >
            Se ingresaron datos de forma incorrecta (e.g.:{" "}
            {criteria === "DOI" ? "10.1234/texto542" : "1234-4321"})
          </Alert>
        )}
        <div style={{ width: "100%", textAlign: "center" }}>
          <h5>{loadingMessage}</h5>
          {loadingMessage && <Spinner animation="border" variant="primary" />}
        </div>
      </div>
    </>
  );
}

export default LoadingPage;
