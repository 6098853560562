import { Card } from "react-bootstrap";

function FAQ() {
  return (
    <>
      <Card style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Card.Header>Preguntas frecuentes</Card.Header>
        <Card.Body>
          <article>
            <h5 style={{ color: "#FF9A9A" }}>¿Qué es HERA?</h5>
            <p>
              HERA es una herramienta que apunta a simplificar, agilizar y
              apoyar el proceso de determinar la calidad y el impacto de un
              recurso académico. Para ello integra información proveniente de
              diferentes bases de datos académicas en forma rápida y concisa.
            </p>
          </article>
          <article>
            <h5 style={{ color: "#FF9A9A" }}>¿Qué es un recurso académico?</h5>
            <p>
              Utilizamos este término para referirnos genéricamente a dos clases
              de contenidos: 1) Artículos de conferencias/revistas, capítulos de
              libros, o cualquier manuscrito que posea un DOI. 2) Publicaciones
              seriadas que posean un ISSN, como por ejemplo, revistas.
            </p>
          </article>
          <article>
            <h5 style={{ color: "#FF9A9A" }}>¿Qué es un DOI?</h5>
            <p>
              Document Object Identifier (DOI) es una cadena alfanumérica única
              para identificar contenido y proveer un vínculo persistente a su
              localización en Internet. Es habitual que las publicaciones
              científicas cuenten con esta clase de identificadores.
            </p>
          </article>
          <article>
            <h5 style={{ color: "#FF9A9A" }}>¿Qué es un ISSN?</h5>
            <p>
              El International Standard Serial Number (ISSN) es un código de 8
              dígitos que sirve para identificar publicaciones periódicas y
              recursos continuos de toda clase y que sean editadas en cualquier
              soporte (en papel o digital).
            </p>
          </article>
          <article>
            <h5 style={{ color: "#FF9A9A" }}>¿Qué información integra HERA?</h5>
            <p>
              Dado un DOI o un ISSN, HERA obtiene información sobre la calidad y
              el impacto del recurso académico en cuestión. Para ello, consulta
              las bases de datos de{" "}
              <a style={{ color: "#FF9A9A" }} href="https://www.crossref.org/">
                CrossRef
              </a>
              ,{" "}
              <a style={{ color: "#FF9A9A" }} href="https://doaj.org/">
                DOAJ
              </a>
              ,{" "}
              <a style={{ color: "#FF9A9A" }} href="https://openalex.org/">
                OpenAlex
              </a>
              ,{" "}
              <a
                style={{ color: "#FF9A9A" }}
                href="https://www.semanticscholar.org/"
              >
                SemanticScholar
              </a>
              ,
              <a style={{ color: "#FF9A9A" }} href="https://redib.org/">
                REDIB
              </a>
              ,{" "}
              <a style={{ color: "#FF9A9A" }} href="https://mjl.clarivate.com/">
                Web of Science
              </a>
              ,{" "}
              <a
                style={{ color: "#FF9A9A" }}
                href="https://www.scopus.com/home.uri"
              >
                Scopus
              </a>
              ,{" "}
              <a style={{ color: "#FF9A9A" }} href="https://www.scimagojr.com/">
                SJR
              </a>
              ,{" "}
              <a style={{ color: "#FF9A9A" }} href="https://www.dimensions.ai/">
                Dimensions
              </a>{" "}
              y{" "}
              <a style={{ color: "#FF9A9A" }} href="https://www.altmetric.com/">
                Altmetric
              </a>
              .
            </p>
          </article>
          <article>
            <h5 style={{ color: "#FF9A9A" }}>
              ¿Qué tan actualizada está la información que provee HERA?
            </h5>
            <p>
              HERA no tiene una base de datos propia sino que funciona en tiempo
              real ante cada búsqueda, consultando diferentes servicios
              provistos por cada una de las bases de datos mencionadas. La
              información exhibida es la que provee cada base de datos en el
              momento de la búsqueda.
            </p>
          </article>
          <article>
            <h5 style={{ color: "#FF9A9A" }}>
              ¿Puedo buscar un artículo que no tenga DOI?
            </h5>
            <p>
              Lamentablemente, por cuestiones de interoperabilidad con las bases
              de datos consultadas, HERA sólo considera aquellos artículos que
              tengan DOI asignado.
            </p>
          </article>
          <article>
            <h5 style={{ color: "#FF9A9A" }}>
              ¿Puedo buscar más de un recurso a la vez?
            </h5>
            <p>
              Sí, es posible. Para ello, se deben listar los identificadores
              separados por coma en la barra de búsqueda.
            </p>
          </article>
          <article>
            <h5 style={{ color: "#FF9A9A" }}>
              ¿Se puede exportar la información que provee HERA?
            </h5>
            <p>
              Sí, es posible. Para ello, se debe hacer clic en alguno de los
              botones etiquetas como “Exportar CSV” o “Exportar JSON”, según el
              formato en el que se quiera el archivo descargable.
            </p>
          </article>
          <article>
            <h5 style={{ color: "#FF9A9A" }}>
              ¿Para qué sirve la opción “Sólo exportar”?
            </h5>
            <p>
              Al marcar esa opción, HERA omite la visualización de la
              información recuperada, disminuyendo el tiempo de respuesta para
              la exportación y posterior descarga de esta.
            </p>
          </article>
        </Card.Body>
      </Card>
    </>
  );
}
export default FAQ;
