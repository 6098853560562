import LoadingPage from "./content/LoadingPage";
import MainCard from "./content/cards/MainCard";
import ExportData from "./content/ExportData";
import BackToTopButton from "./BackToTopButton";
import { useSelector } from "react-redux";
import OnlyExportCard from "./content/cards/OnlyExportCard";

function Content() {
  const onlyExport = useSelector((state) => state.search.onlyExport);

  return (
    <>
      <LoadingPage />
      {!onlyExport ? (
        <>
          <ExportData />
          <MainCard />
        </>
      ) : (
        <OnlyExportCard />
      )}
      <BackToTopButton />
    </>
  );
}

export default Content;
