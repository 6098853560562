import { useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import ListCardDoiResume from "./listCardsResume/ListCardDoiResume";
import ListCardIssnResume from "./listCardsResume/ListCardIssnResume";

function ResumeCard({ content }) {
  const criteria = useSelector((state) => state.search.criteria);

  return (
    <>
      {criteria === "DOI" ? <ListCardDoiResume content={content} /> : null}
      {criteria === "ISSN" || content.issnData ? (
        <ListCardIssnResume content={content} />
      ) : (
        <Alert
          className="text-center"
          style={{ marginTop: "1rem" }}
          key={content.doi}
          variant="danger"
        >
          No se encontró informacion asociada del ISSN para el DOI:{" "}
          <b>{content.doi}</b>
        </Alert>
      )}
    </>
  );
}

export default ResumeCard;
