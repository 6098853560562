import OpenAlexDoiDetails from "./DOI/OpenAlexDoiDetails";
import DoajDoiDetails from "./DOI/DoajDoiDetails";
import SemanticScholarDoiDetails from "./DOI/SemanticScholarDoiDetails";
import DimensionsDoiDetails from "./DOI/DimensionsDoiDetails";
import AltmetricDoiDetails from "./DOI/AltmetricDoiDetails";
import { Row, Col } from "react-bootstrap";
import CrossrefDoiDetails from "./DOI/CrossrefDoiDetails";
import GoogleScholarDoiDetails from "./DOI/GoogleScholarDoiDetails";
import { getGlobalConfig, getApiConfig } from "../../../../config";
import ScopusDoiDetails from "./DOI/ScopusDoiDetails";

function ListCardsDoiDetails({ content }) {
  const config = getGlobalConfig();

  const openAlexDOIConfig = getApiConfig(config, "DOI", "OpenAlexDOI");
  const doajDOIConfig = getApiConfig(config, "DOI", "DoajDOI");
  const semanticScholarConfig = getApiConfig(config, "DOI", "SemanticScholar");
  const dimensionsConfig = getApiConfig(config, "DOI", "Dimensions");
  const altmetricConfig = getApiConfig(config, "DOI", "Altmetric");
  const crossrefDOIConfig = getApiConfig(config, "DOI", "CrossrefDOI");
  const ScopusDOIConfig = getApiConfig(config, "DOI", "ScopusDOI");

  return (
    <>
      <Row>
        {crossrefDOIConfig.enabled ? (
          <Col xs={12} sm={4}>
            <CrossrefDoiDetails content={content.CrossrefDOI} />
          </Col>
        ) : null}
        <Col xs={12} sm={4}>
          <GoogleScholarDoiDetails title={content.title} />
        </Col>
        <Col xs={12} sm={4}>
          {" "}
          {ScopusDOIConfig.enabled ? (
            <ScopusDoiDetails content={content.ScopusDOI} />
          ) : null}{" "}
        </Col>
      </Row>
      {openAlexDOIConfig.enabled ? (
        <OpenAlexDoiDetails content={content.OpenAlexDOI} />
      ) : null}
      <Row>
        {doajDOIConfig.enabled ? (
          <Col>
            <DoajDoiDetails content={content.DoajDOI} />
          </Col>
        ) : null}
        {semanticScholarConfig.enabled ? (
          <Col>
            {" "}
            <SemanticScholarDoiDetails content={content.SemanticScholar} />{" "}
          </Col>
        ) : null}
      </Row>
      <Row>
        {dimensionsConfig.enabled ? (
          <Col>
            <DimensionsDoiDetails content={content.Dimensions} />
          </Col>
        ) : null}
        {altmetricConfig.enabled ? (
          <Col>
            {" "}
            <AltmetricDoiDetails content={content.Altmetric} />
          </Col>
        ) : null}
      </Row>
    </>
  );
}

export default ListCardsDoiDetails;
