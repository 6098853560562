import { Image } from "react-bootstrap";
//import newheralogo from '../images/newheralogo.png'
import newheralogo from "../images/resize/heralogo2.png";

import { Link } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";

function Header() {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <Image style={{ width: "12rem" }} src={newheralogo} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav nav-link-font">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" className="nav-link-font">
              Buscador
            </Nav.Link>
            <Nav.Link as={Link} to="/about-us" className="nav-link-font">
              Sobre HERA
            </Nav.Link>
            <Nav.Link as={Link} to="/faq" className="nav-link-font">
              FAQ
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="https://chrome.google.com/webstore/detail/hera-browser-extension/cdmmeadgfiafkgpdiccepbmngbbfghcm/related?hl=es&authuser=0"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link-font"
            >
              Complemento web
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
