import { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import sjrLogo from "../../../../../images/resize/SJR-orange-logo.png";

function ScimagoIssnDetails({ content }) {
  const scimagoWidgetRef = useRef(null);

  useEffect(() => {
    if (scimagoWidgetRef.current) {
            const img = scimagoWidgetRef?.current?.querySelector('img');
            if (img) {
              img.style.width = '13em';
          }
        }
    }, []);

  return (
    <>
      <Card
        className="tab-color-scimago-detail"
        style={{ marginTop: "1em" }}
        bg="light"
        text="black"
      >
        <Card.Body>
          <Row>
            <Col xs={12} sm={2}>
              <a
                href={content.journalURL ?? `https://www.scimagojr.com/`}
                target="_blank"
                rel="noreferrer"
              >
                <Card.Img
                  variant="top"
                  alt="Web of Science"
                  style={{ width: "8rem" }}
                  src={sjrLogo}
                />
              </a>
            </Col>

            {!content.notFound ? (
              <>
                <Col xs={12} sm={3}>
                  <div
                    ref={scimagoWidgetRef}
                    id="scimagoWidget"
                    dangerouslySetInnerHTML={{ __html: content.embedString }}
                  ></div>
                </Col>

                <Col xs={12} sm={7}>
                  <span>
                    Indice: <b>{content.hIndex}</b>{" "}
                  </span>
                  <br></br>
                  <span>
                    Cobertura: <b>{content.coverage}</b>
                  </span>
                  <br></br>
                  <p>
                    Pais: <b>{content.country}</b>
                  </p>
                  <p>
                    SCImago Journal Rank (SJR) es una medida de la influencia
                    científica de las revistas académicas que tiene en cuenta
                    tanto el número de citas recibidas por una revista como la
                    importancia o prestigio de las revistas de donde provienen
                    dichas citas.
                  </p>

                  <div style={{ fontSize: "0.8em", color: "grey" }}>
                    Más información en “
                    <a
                      href="https://www.scimagojr.com/help.php"
                      target="_blank"
                      rel="noreferrer"
                    >
                      SJR | Help
                    </a>
                    ” y “
                    <a
                      href="https://www.scimagojr.com/help.php?q=FAQ"
                      target="_blank"
                      rel="noreferrer"
                    >
                      SJR | FAQ
                    </a>
                    ”
                  </div>
                </Col>
              </>
            ) : (
              <Col>
                <p>Recurso no encontrado</p>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default ScimagoIssnDetails;
