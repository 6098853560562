import Card from "react-bootstrap/Card";
import { Button, Col, Row } from "react-bootstrap";
import { svgSealDOAJ } from "../../contants";
import doajLogo from "../../../../../images/resize/DOAJ-logo-v1.png";

import { BsCheck2Circle } from "react-icons/bs";
import { MdAttribution } from "react-icons/md";
import { AiOutlineCopyright } from "react-icons/ai";
import { TbNoDerivatives } from "react-icons/tb";
import { MdMoneyOff } from "react-icons/md";
import { GiRapidshareArrow } from "react-icons/gi";

const renderLicense = (index, licenseType) => {
  switch (licenseType) {
    case "CC BY":
      return (
        <div className="text-center" key={index}>
          <span>Licencias usadas</span>
          <a href="https://creativecommons.org/licenses/by/4.0/">
            <p style={{ fontSize: "2rem", color: "black" }}>
              {" "}
              <AiOutlineCopyright />
              <MdAttribution />
            </p>
            {/* <img style={{ width: '6rem', marginTop: '1rem' }} src={ccBy} alt="cc-by" /> */}
          </a>
        </div>
      );
    case "CC BY-ND":
      return (
        <div className="text-center" key={index}>
          <span>Licencias usadas</span>
          <a href="https://creativecommons.org/licenses/by-nd/4.0/">
            {/* <img style={{ width: '6rem', marginTop: '1rem' }} src={ccByNd} alt="cc-by-nd" /> */}
            <p style={{ fontSize: "2rem", color: "black" }}>
              {" "}
              <AiOutlineCopyright />
              <MdAttribution />
              <TbNoDerivatives />
            </p>
          </a>
        </div>
      );
    case "CC BY-NC":
      return (
        <div className="text-center" key={index}>
          <span>Licencias usadas</span>
          <a href="https://creativecommons.org/licenses/by-nc/4.0/">
            {/* <img style={{ width: '6rem', marginTop: '1rem' }} src={ccByNc} alt="cc-by-nc" /> */}
            <p style={{ fontSize: "2rem", color: "black" }}>
              {" "}
              <AiOutlineCopyright />
              <MdAttribution />
              <MdMoneyOff />
            </p>
          </a>
        </div>
      );
    case "CC BY-SA":
      return (
        <div className="text-center" key={index}>
          <span>Licencias usadas</span>
          <a href="https://creativecommons.org/licenses/by-sa/4.0/">
            {/* <img style={{ width: '6rem', marginTop: '1rem' }} src={ccBySa} alt="cc-by-sa" /> */}
            <p style={{ fontSize: "2rem", color: "black" }}>
              {" "}
              <AiOutlineCopyright />
              <MdAttribution />
              <GiRapidshareArrow />
            </p>
          </a>
        </div>
      );
    case "CC BY-NC-ND":
      return (
        <div className="text-center" key={index}>
          <span>Licencias usadas</span>
          <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/">
            {/* <img style={{ width: '6rem', marginTop: '1rem' }} src={ccByNcNd} alt="cc-by-nc-nd" /> */}
            <p style={{ fontSize: "2rem", color: "black" }}>
              {" "}
              <AiOutlineCopyright />
              <MdAttribution />
              <MdMoneyOff />
              <TbNoDerivatives />
            </p>
          </a>
        </div>
      );
    case "CC BY-NC-SA":
      return (
        <div className="text-center" key={index}>
          <span>Licencias usadas</span>
          <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/">
            {/* <img style={{ width: '6rem', marginTop: '1rem' }} src={ccByNcSa} alt="cc-by-nc-nd" /> */}
            <p style={{ fontSize: "2rem", color: "black" }}>
              {" "}
              <AiOutlineCopyright />
              <MdAttribution />
              <MdMoneyOff />
              <GiRapidshareArrow />
            </p>
          </a>
        </div>
      );
    default:
      return null;
  }
};

function DoajIssnDetails({ content }) {
  return (
    <>
      <Card
        className="tab-color-doaj-detail"
        bg="light"
        style={{ marginTop: "2rem", height: "100%" }}
      >
        <Card.Body>
          <Row>
            <Col xs={12} sm={2}>
              <a href={content.link} target="_blank" rel="noreferrer">
                <Card.Img
                  style={{ width: "6rem" }}
                  variant="top"
                  alt="Doaj Logo"
                  src={doajLogo}
                />
              </a>
            </Col>

            {content.notFound ? (
              <Col style={{ textAlign: "center" }}>Recurso no encontrado.</Col>
            ) : (
              <>
                {content.seal ? (
                  <Col xs={12} sm={5}>
                    <>
                      {content.seal ? (
                        <div className="text-center">
                          <p>
                            <BsCheck2Circle /> Indexado
                          </p>
                          <Button
                            variant="light"
                            href="https://doaj.org/apply/seal"
                          >
                            {svgSealDOAJ}
                          </Button>
                        </div>
                      ) : null}
                    </>
                  </Col>
                ) : null}
                <Col xs={12} sm={5}>
                  {content.max_price ? (
                    <p>
                      El costo más alto por publicar en esta revista es:
                      <b style={{ fontSize: "1.2em" }}>
                        {" "}
                        {content.max_price} {content.currency}
                      </b>
                    </p>
                  ) : null}
                  {content.license && (
                    <>
                      {content.license.map((license, index) =>
                        renderLicense(index, license.type),
                      )}
                    </>
                  )}
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default DoajIssnDetails;
