import { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

export default function ChartsBarCrossref(doisYear) {
  const { doisYear: arrayDoisYear } = doisYear;
  const sortedArrayDoisYear = useMemo(() => {
    return [...arrayDoisYear].sort((a, b) => a.year - b.year);
  }, [arrayDoisYear]);

  const data = useMemo(
    function () {
      return {
        datasets: [
          {
            label: "Artículos por año",
            tension: 0.3,
            data: sortedArrayDoisYear.map(function (item) {
              return item.dois_by_count;
            }),
            borderColor: "red",
            //backgroundColor: "rgba(75, 192, 192, 0.3)",
            backgroundColor: " #ffaaaa ",
          },
        ],
        labels: sortedArrayDoisYear.map(function (item) {
          return item.year;
        }),
      };
    },
    [sortedArrayDoisYear],
  );

  return <Bar data={data} options={options} />;
}
