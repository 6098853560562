import DoajIssnDetails from "./ISSN/DoajIssnDetails";
import OpenAlexIssnDetails from "./ISSN/OpenAlexIssnDetails";
import RedibIssnDetails from "./ISSN/RedibIssnDetails";
import WosIssnDetails from "./ISSN/WosIssnDetails";
import ScopusIssnDetails from "./ISSN/ScopusIssnDetails";
import ScimagoIssnDetails from "./ISSN/ScimagoIssnDetails";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import CrossrefIssnDetails from "./ISSN/CrossrefIssnDetails";
import { getGlobalConfig, getApiConfig } from "../../../../config";

function ListCardsIssnDetails({ content }) {
  const config = getGlobalConfig();
  const openAlexIssnConfig = getApiConfig(config, "ISSN", "OpenAlexISSN");
  const doajIssnConfig = getApiConfig(config, "ISSN", "DoajISSN");
  const wosConfig = getApiConfig(config, "ISSN", "WosISSN");
  const scopusConfig = getApiConfig(config, "ISSN", "ScopusIssn");
  const rebidConfig = getApiConfig(config, "ISSN", "RedibISSN");
  const scimagoConfig = getApiConfig(config, "ISSN", "ScimagoIssn");
  const crossrefISSNConfig = getApiConfig(config, "ISSN", "CrossrefISSN");

  const criteria = useSelector((state) => state.search.criteria);
  let data = content;
  let issnValue;
  if (criteria === "DOI") {
    data = content.issnData;
    issnValue = content.issn;
  }

  const publisher = data.publisher;
  const url = data.URL;
  const title = data.title;

  return (
    <>
      <Row>
        {criteria === "ISSN" ? null : (
          <>
            <Col>
              <h4 style={{ color: "grey", marginTop: "3rem" }}>
                Publicado en:{" "}
                <a
                  href={url}
                  style={{ color: "#ffaaaa" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {title}{" "}
                </a>{" "}
                <span style={{ fontSize: "18px", color: "grey" }}>
                  ({issnValue}), {publisher}
                </span>{" "}
              </h4>
            </Col>
          </>
        )}
      </Row>
      {crossrefISSNConfig.enabled ? (
        <Col>
          <CrossrefIssnDetails content={data.CrossrefISSN} />
        </Col>
      ) : null}
      <Row>
        {openAlexIssnConfig.enabled ? (
          <Col>
            <OpenAlexIssnDetails content={data.OpenAlexISSN} />
          </Col>
        ) : null}
        {wosConfig.enabled ? (
          <Col>
            <WosIssnDetails content={data.WosISSN} />
          </Col>
        ) : null}
      </Row>
      <Row>
        {doajIssnConfig.enabled ? (
          <Col xs={12} sm={6}>
            <DoajIssnDetails content={data.DoajISSN} />
          </Col>
        ) : null}
        {rebidConfig.enabled ? (
          <Col>
            <RedibIssnDetails
              content={data.RedibISSN}
              issnValue={data.identifier.value}
            />
          </Col>
        ) : null}
      </Row>
      {scopusConfig.enabled ? (
        <ScopusIssnDetails content={data.ScopusIssn} />
      ) : null}
      {scimagoConfig.enabled ? (
        <ScimagoIssnDetails content={data.ScimagoIssn} />
      ) : null}
    </>
  );
}

export default ListCardsIssnDetails;
