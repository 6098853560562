import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import { svgSealDOAJ } from "../../contants";
import doajLogo from "../../../../../images/resize/DOAJ-logo-v1.png";

function DoajDoiResume({ content }) {
  //const content = useSelector(state => state.content.value.DoajDOI)
  // console.log(content)

  return (
    <Card
      className="text-center bg-doaj-gradient"
      style={{ alignItems: "center" }}
      text="white"
    >
      {/* <a style={{ marginTop: '1rem', width: '12rem' }}>{svgLogoDOAJ}</a> */}
      {content.notFound ? (
        <>
          <a href={"https://doaj.org/"} target="_blank" rel="noreferrer">
            <Card.Img
              variant="top"
              alt="Doaj Logo"
              style={{ marginTop: "1rem", width: "100%" }}
              src={doajLogo}
            />
          </a>
          <p style={{ fontSize: "20px", marginTop: "18px" }}>
            Recurso no encontrado
          </p>
        </>
      ) : (
        <>
          <a href={content.link} target="_blank" rel="noreferrer">
            <Card.Img
              variant="top"
              alt="Doaj Logo"
              style={{ marginTop: "1rem", width: "100%" }}
              src={doajLogo}
            />
          </a>

          <Card.Body>
            <Card.Title>
              {content.seal ? (
                <Button
                  style={{ marginTop: "1rem" }}
                  variant="light"
                  href="https://doaj.org/apply/seal"
                >
                  {svgSealDOAJ}{" "}
                </Button>
              ) : (
                <p>Indexado</p>
              )}
            </Card.Title>
          </Card.Body>
        </>
      )}
    </Card>
  );
}

export default DoajDoiResume;
