import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    query: undefined,
    criteria: undefined,
    loadStage: 0,
    formatError: false,
    onlyExport: false,
  },
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setCriteria: (state, action) => {
      state.criteria = action.payload;
    },
    setLoadStage: (state, action) => {
      state.loadStage = action.payload;
    },
    setFormatError: (state, action) => {
      state.formatError = action.payload;
    },
    setOnlyExport: (state, action) => {
      state.onlyExport = action.payload;
    },
  },
});

export const {
  setQuery,
  setCriteria,
  setLoadStage,
  setFormatError,
  setOnlyExport,
} = searchSlice.actions;
export default searchSlice.reducer;
