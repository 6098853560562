import { Row, Button, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { formatCsv, formatJson } from "../../api-front/formatCsv";

import { BsFiletypeJson, BsFiletypeCsv } from "react-icons/bs";

function ExportData() {
  const data = useSelector((state) => state.content.value);
  const criteria = useSelector((state) => state.search.criteria);

  const getCSV = async (e) => {
    const csv = await formatCsv(data, criteria);
    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvUrl = URL.createObjectURL(csvData);
    const tempLink = document.createElement("a");
    tempLink.href = csvUrl;
    tempLink.setAttribute("download", "data.csv");
    tempLink.click();
  };

  const getJSON = async (e) => {
    // Function JSON
    const jsonFormated = await formatJson(data, criteria);
    const jsonData = JSON.stringify(jsonFormated, null, 2);
    const jsonBlob = new Blob([jsonData], {
      type: "application/json;charset=utf-8;",
    });
    const jsonUrl = URL.createObjectURL(jsonBlob);
    const tempLink = document.createElement("a");
    tempLink.href = jsonUrl;
    tempLink.setAttribute("download", "data.json");
    tempLink.click();
  };

  return (
    <>
      {data !== undefined ? (
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              type="submit"
              variant="outline-success"
              onClick={getCSV}
              disabled={data === undefined}
              size="sm"
              className="me-2"
            >
              Exportar <BsFiletypeCsv style={{ scale: "1.5" }} />
            </Button>
            <Button
              type="submit"
              variant="outline-warning"
              size="sm"
              onClick={getJSON}
              disabled={data === undefined}
            >
              Exportar <BsFiletypeJson style={{ scale: "1.5" }} />
            </Button>
          </Col>
        </Row>
      ) : null}
    </>
  );
}

export default ExportData;
