import { useEffect, useRef } from "react";
import Card from "react-bootstrap/Card";
import sjrLogo from "../../../../../images/resize/SJR-white-logo.png";

function ScimagoIssnResume({ content }) {

  const scimagoWidgetRef = useRef(null);

  useEffect(() => {
    if (scimagoWidgetRef.current) {
      const img = scimagoWidgetRef.current.querySelector('img');
      if (img) {
        img.style.width = '10em';
    }
    }
  }, []);

  return (
    <Card
      className="text-center bg-scimago-gradient"
      style={{ alignItems: "center" }}
      bg="light"
      text="black"
    >

      <a
        href={content.journalURL ?? `https://www.scimagojr.com/`}
        target="_blank"
        rel="noreferrer"
      >
        <Card.Img
          variant="top"
          alt="Web of Science"
          style={{ marginTop: "2rem", width: "100%" }}
          src={sjrLogo}
        />
      </a>
      <Card.Body>
        <Card.Text>
          {!content.notFound ? (
            <div
              style={{ marginTop: "-1em" }}
              ref={scimagoWidgetRef}
              id="scimagoWidget"
              dangerouslySetInnerHTML={{ __html: content.embedString }}
            />
          ) : (
            <p style={{ fontSize: "20px", color: "white" }}>
              Recurso no encontrado
            </p>
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default ScimagoIssnResume;