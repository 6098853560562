import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Pagination, Spinner } from "react-bootstrap";
import CardDOI from "./CardDOI";
import CardISSN from "./CardISSN";

const itemsPerPage = 5;

function MainCard() {
  const criteria = useSelector((state) => state.search.criteria);
  const arrayContent = useSelector((state) => state.content.value);

  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedContent, setPaginatedContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    setPaginatedContent(
      arrayContent?.results?.slice(startIndex, endIndex) || [],
    );
  }, [arrayContent, currentPage]);

  const totalPages =
    Math.ceil(arrayContent?.results?.length / itemsPerPage) || 1;

  const handlePageClick = async (pageNumber) => {
    setIsLoading(true);
    setCurrentPage(pageNumber);
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Espera 4 segundo
    setIsLoading(false);
  };

  const renderPaginationItems = () => {
    const items = [];
    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
      items.push(
        <Pagination.Item
          key={pageNumber}
          active={pageNumber === currentPage}
          onClick={() => handlePageClick(pageNumber)}
        >
          {pageNumber}
        </Pagination.Item>,
      );
    }
    return items;
  };

  const renderContentAndPagination = (ContentComponent) => {
    return (
      <>
        {paginatedContent.map((content, index) => (
          <ContentComponent
            key={content.doi || content.title}
            content={content}
            isFirst={index === 0}
          />
        ))}
        {totalPages > 1 ? (
          <div
            className="d-flex justify-content-center"
            style={{ paddingTop: "1em" }}
          >
            <Pagination>
              <Pagination.Prev
                onClick={() => handlePageClick(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {renderPaginationItems()}
              <Pagination.Next
                onClick={() => handlePageClick(currentPage + 1)}
                disabled={
                  currentPage * itemsPerPage >= arrayContent?.results?.length
                }
              />
            </Pagination>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {criteria === "DOI" && renderContentAndPagination(CardDOI)}
          {criteria === "ISSN" && renderContentAndPagination(CardISSN)}
        </>
      )}
    </>
  );
}

export default MainCard;
