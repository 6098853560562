import Card from "react-bootstrap/Card";
import crossrefLogo from "../../../../../images/resize/crossref-logo.png";
import { Col, Row } from "react-bootstrap";

function CrossrefDoiDetails({ content }) {
  return (
    <Card
      className="tab-color-crossref-detail"
      bg="light"
      style={{ marginTop: "2rem", height: "100%" }}
    >
      <Card.Body>
        <Row>
          <Col xs={12} sm={5}>
            <a href={content.url} target="_blank" rel="noreferrer">
              <Card.Img
                variant="top"
                style={{ width: "8rem" }}
                src={crossrefLogo}
              />
            </a>
          </Col>
          {content.notFound ? (
            <Col>
              <p>Recurso no encontrado</p>{" "}
            </Col>
          ) : (
            <Col>
              {content.cites ? (
                <>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      fontSize: "1.2rem",
                    }}
                  >
                    {" "}
                    Número de citas:{" "}
                  </p>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      fontSize: "2rem",
                    }}
                  >
                    <b>{content.cites}</b>
                  </p>
                </>
              ) : (
                <Col>
                  <p>No se encontraron citas</p>{" "}
                </Col>
              )}
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
}

export default CrossrefDoiDetails;
