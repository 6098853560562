// apiConfig.js
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { storeConfig } from "../slicers/configSlicer";
import { setGlobalConfig } from "../config";

const useApiConfig = () => {
  const config = useSelector((state) => state.config.data);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/config`,
        );
        const data = await response.json();
        dispatch(storeConfig(data));
        setGlobalConfig(data);
      } catch (error) {
        console.error("Error al obtener la configuración", error);
      }
    };

    if (!config) {
      fetchConfig();
    }
  }, [config, dispatch]);

  return config;
};

export default useApiConfig;
