import Card from "react-bootstrap/Card";
import openAlexlogo from "../../../../../images/resize/OpenAlex-logo.png";

function OpenAlexDoiResume({ content }) {
  //const content = useSelector(state => state.content.value.OpenAlexDOI)

  return (
    <Card
      className="text-center bg-openalex-gradient"
      style={{ alignItems: "center" }}
    >
      <a href={content.id} target="_blank" rel="noreferrer">
        <Card.Img
          variant="top"
          alt="Open Alex Logo"
          style={{ marginTop: "1rem", width: "100%" }}
          src={openAlexlogo}
        />
      </a>
      <Card.Body>
        <Card.Title>
          {!content.notFound ? (
            <p>
              <span style={{ fontSize: "1.2rem", color: "white" }}>Citas:</span>{" "}
              <br></br>
              <b style={{ fontSize: "2.5rem", color: "white" }}>
                {" "}
                {content?.cited_by_count ?? null}
              </b>
            </p>
          ) : (
            <p style={{ color: "white" }}>{content.error}</p>
          )}
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

export default OpenAlexDoiResume;
