import Card from "react-bootstrap/Card";
import dimensionsLogo from "../../../../../images/dimensionsLogo.png";
import { Col, Row } from "react-bootstrap";
import { useDimensions } from "../../../../../hooks/useScript";

function DimensionsDoiDetails({ content }) {
  useDimensions("https://badge.dimensions.ai/badge.js");

  return (
    <Card
      className="tab-color-dimensions-detail"
      bg="light"
      style={{ marginTop: "2rem", height: "100%" }}
    >
      <Card.Body>
        <Row>
          <Col xs={12} sm={3}>
            {content.doi ? (
              <span
                className="__dimensions_badge_embed__"
                data-doi={content.doi}
              />
            ) : (
              <Card.Img
                variant="top"
                style={{ width: "5rem" }}
                src={dimensionsLogo}
              />
            )}
          </Col>
          <Col>
            {content.field_citation_ratio ? (
              <p>
                {content.field_citation_ratio > 1.0
                  ? "Es muy citado en relación a otros del mismo campo con similar antigüedad"
                  : "No es muy citado en relación a otros del mismo campo con similar antigüedad"}
              </p>
            ) : (
              "Sin información estadística sobre citas de artículos con similar antigüedad"
            )}
            {content.relative_citation_ratio ? (
              <p>
                {content.relative_citation_ratio > 1.0
                  ? "Es muy citado en relación a otros del mismo campo"
                  : "No es muy citado en relación a otros del mismo campo"}
              </p>
            ) : (
              <p>
                Sin información estadística sobre citas de artículos del mismo
                campo
              </p>
            )}
            <i style={{ fontSize: "10px" }}>
              Data sourced from Dimensions, by Digital Science (
              <a href="https://www.dimensions.ai">https://www.dimensions.ai</a>
              ).
            </i>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default DimensionsDoiDetails;
