import { useSelector } from "react-redux";
import ExportData from "../ExportData";
import { Button, Card, Col, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { BsFiletypeJson, BsFiletypeCsv } from "react-icons/bs";

function OnlyExportCard({ content }) {
  const criteria = useSelector((state) => state.search.criteria);
  const query = useSelector((state) => state.search.query);
  const data = useSelector((state) => state.content.value);
  const items = query.split(",");
  const half = Math.ceil(items.length / 2);
  const leftItems = items.slice(0, half);
  const rightItems = items.slice(half);

  return (
    <>
      <Card>
        <Card.Header>Exportar información</Card.Header>
        <Row>
          <Col>
            <Card.Body>
              <p>Criterio: {criteria}</p>
              <Card bg="light">
                <Card.Body>
                  <p>Exportar datos de:</p>
                  <Row>
                    <Col>
                      <ul>
                        {leftItems.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </Col>
                    <Col>
                      <ul>
                        {rightItems.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <div style={{ marginTop: "1rem" }}>
                {data ? (
                  <ExportData />
                ) : (
                  <>
                    <Row>
                      <Col className="d-flex justify-content-end">
                        <Button
                          type="submit"
                          variant="outline-success"
                          size="sm"
                          className="me-2"
                          disabled={true}
                        >
                          Exportar <BsFiletypeCsv style={{ scale: "1.5" }} />{" "}
                          <Spinner size="sm" />
                        </Button>
                        <Button
                          type="submit"
                          variant="outline-warning"
                          size="sm"
                          disabled={true}
                        >
                          Exportar <BsFiletypeJson style={{ scale: "1.5" }} />{" "}
                          <Spinner size="sm" />
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default OnlyExportCard;
