import { configureStore } from "@reduxjs/toolkit";
import contentSlicer from "./slicers/contentSlicer";
import searchSlicer from "./slicers/searchSlicer";
import configReducer from "./slicers/configSlicer";

export const store = configureStore({
  reducer: {
    content: contentSlicer,
    search: searchSlicer,
    config: configReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["content/setContent"],
        // Ignore these field paths in all actions
        //ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ["content.value"],
      },
    }),
});
