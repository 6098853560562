import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import { validDOI, validISSN } from "../helpers/regex";
import { useDispatch } from "react-redux";
import { setContent } from "../slicers/contentSlicer";
import {
  setQuery,
  setCriteria,
  setLoadStage,
  setFormatError,
  setOnlyExport,
} from "../slicers/searchSlicer";
import { getDataByQuery } from "../api-front/dataBuilder";
import { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useLocation } from "react-router-dom";

const popover = (
  <Popover id="popover-positioned-bottom">
    <Popover.Header as="h3">Sólo exportar</Popover.Header>
    <Popover.Body>
      Al tildar esta opción, sólo se ofrece la exportación de los datos, sin
      mostrarla en la web, reduciendo el tiempo de procesamiento.
    </Popover.Body>
  </Popover>
);

function Search() {
  const [onlyExport, setOnlyExportAux] = useState(false); // Local state for onlyExport
  const [queryAux, setQueryAux] = useState(""); // Local state for onlyExport
  const [urlParams, setUrlParams] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();
  const [criteriaAux, setCriteriaAux] = useState(undefined);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const doiParam = searchParams.get("doi");
    if (doiParam) {
      setQueryAux(doiParam);
      setCriteriaAux("DOI");
      setUrlParams(true);
      // Realiza la búsqueda usando el DOI
    } else {
      const issnParam = searchParams.get("issn");
      if (issnParam) {
        setQueryAux(issnParam);
        setCriteriaAux("ISSN");
        setUrlParams(true);
      // Realiza la busqueda por ISSN
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (urlParams) {
      validateQuery();
    }
  }, [urlParams]);

  const handleOnlyExportChange = (e) => {
    setOnlyExportAux(e.target.checked);
  };

  const radios = [
    { name: "DOI", value: "DOI" },
    { name: "ISSN", value: "ISSN" },
  ];

  const searchPapers = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (criteriaAux === "DOI") {
      dispatch(setLoadStage(1));
    } else {
      dispatch(setLoadStage(2));
    }
    const res = await getDataByQuery(queryAux, criteriaAux);
    dispatch(setContent(res));

    dispatch(setFormatError(false));
    dispatch(setLoadStage(0));
  };

  const validateItems = (items, validator) => {
    for (let item of items) {
      if (!validator.test(item)) {
        return false;
      }
    }
    return true;
  };

  const validateQuery = async (e) => {
    if (e) {
      e.preventDefault();
    }
    dispatch(setCriteria(criteriaAux));

    const stringWithoutSpaces = queryAux.replace(/\s/g, "");
    const items = stringWithoutSpaces.split(",");

    if (criteriaAux === "DOI") {
      if (validateItems(items, validDOI)) {
        dispatch(setQuery(queryAux));
        dispatch(setOnlyExport(onlyExport));
        if (e) {
          searchPapers(e);
        } else {
          searchPapers();
        }
      } else {
        dispatch(setFormatError(true));
      }
    } else if (criteriaAux === "ISSN") {
      if (validateItems(items, validISSN)) {
        dispatch(setQuery(queryAux));
        dispatch(setOnlyExport(onlyExport));
        if (e) {
          searchPapers(e);
        } else {
          searchPapers();
        }
      } else {
        dispatch(setFormatError(true));
      }
    }
  };

  return (
    <>
      <Form onSubmit={validateQuery} style={{ marginTop: "1rem" }}>
        <Row className="justify-content-md-center">
          <Col xs={12} sm={12} md={6}>
            <InputGroup>
              <Form.Control
                placeholder={
                  criteriaAux === "DOI"
                    ? "Ejemplo: 10.1000/xyz123"
                    : criteriaAux === "ISSN"
                    ? "Ejemplo: 2049-3630"
                    : "Seleccione DOI o ISSN"
                }
                aria-label="Buscar"
                value={queryAux}
                onChange={(e) => setQueryAux(e.target.value)}
              />
              <ButtonGroup>
                <InputGroup>
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      variant="outline-secondary"
                      name="radio"
                      value={radio.value}
                      checked={criteriaAux === radio.value}
                      onChange={(e) => {
                        setCriteriaAux(e.currentTarget.value);
                      }}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </InputGroup>
              </ButtonGroup>
            </InputGroup>
          </Col>
          <Col md={2}>
            <Button
              type="submit"
              className="custom-btn"
              onClick={(e) => dispatch(setContent(undefined))}
              disabled={
                criteriaAux !== "DOI" && criteriaAux !== "ISSN" ? true : false
              }
            >
              {" "}
              <BsSearch style={{ scale: "1.3" }} /> Buscar{" "}
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={8}>
            <div
              className="d-flex align-items-center"
              style={{ paddingTop: "3.5px" }}
            >
              <Form.Check
                id="export"
                label="Sólo exportar"
                className="me-1"
                checked={onlyExport}
                onChange={handleOnlyExportChange}
                style={{ fontSize: "15px" }}
              />
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={popover}
              >
                <a
                  style={{
                    color: "#f08080",
                    borderRadius: "100%",
                    padding: "1.5px",
                    backgroundColor: "#f7f7f7",
                    borderBottom: "groove",
                    fontSize: "14px",
                  }}
                >
                  <b>&nbsp;&nbsp;?&nbsp;&nbsp;</b>
                </a>
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default Search;
