import Card from "react-bootstrap/Card";
import redibLogo from "../../../../../images/resize/redib-logo.png";

function RedibIssnResume({ content, issnValue }) {
  //const content = useSelector(state => state.content.value.issnData.RedibISSN)
  //const issnValue = content.issnData.identifier.value
  // console.log(content)

  return (
    <>
      <Card
        className="text-center bg-redib-gradient"
        style={{ alignItems: "center" }}
        bg="light"
        text="black"
      >
        <a
          href={`https://redib.org/Search/Results?type=ISN&lookfor=${issnValue}`}
          target="_blank"
          rel="noreferrer"
        >
          <Card.Img
            variant="top"
            alt="Web of Science"
            style={{ marginTop: "1rem", width: "90%" }}
            src={redibLogo}
          />
        </a>
        <Card.Body>
          <Card.Text>
            {/* {!content.notFound ? 
                    content.widget ?

                        <a href={content.widget.anchorHref}>
                            <img border="0" width="125px" height="125px" src={content.widget.imgSrc} alt="redib_rank_widget" />
                        </a>

                        :
                        <>
                            <a href={content.redibURL}><img className="card--tiny--image big" src={redibLogo} alt="redib_logo" /></a>

                            <div style={{ fontFamily: 'Roboto', padding: "1.5rem" }} className="card--tiny--info--data">Indicadores de calidad editorial</div>
                            {content?.indicadores?.map(indicador =>
                                <div key={indicador} style={{ fontFamily: 'Roboto' }} >
                                    {indicador}
                                </div>
                            )}

                        </>

                        : */}
            <p style={{ fontSize: "20px", marginTop: "25px" }}>
              {/* {content?.error} */}
              Servidor no disponible
            </p>

            {/* } */}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

export default RedibIssnResume;
