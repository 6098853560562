import Card from "react-bootstrap/Card";
import crossrefLogo from "../../../../../images/resize/crossref-logo.png";
import { Col, Row } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import ChartsBarCrossref from "../../ChartsBarCrossref";

function CrossrefIssnDetails({ content }) {
  return (
    <Card
      className="tab-color-crossref-detail"
      bg="light"
      style={{ marginTop: "2rem", height: "100%" }}
    >
      <Card.Body>
        <Row>
          <Col xs={12} sm={2}>
            <a href={content.url} target="_blank" rel="noreferrer">
              <Card.Img
                variant="top"
                style={{ width: "8rem" }}
                src={crossrefLogo}
              />
            </a>
          </Col>
          {content.notFound ? (
            <Col>
              <p>Recurso no encontrado</p>{" "}
            </Col>
          ) : (
            <>
              <Col>
                <p style={{ fontSize: "1.2rem" }}>
                  {" "}
                  Artículos: <b>{content.total_dois}</b>
                </p>
                <p>
                  <b>
                    {(content.coverage["award-numbers-current"] * 100).toFixed(
                      1,
                    )}
                    %{" "}
                  </b>
                  de los artículos en esta revista tienen algún award
                </p>
                <p>
                  <b>
                    {(content.coverage["orcids-current"] * 100).toFixed(1)}%
                  </b>{" "}
                  de los autores de la revista tienen un{" "}
                  <a href="https://orcid.org/">ORCID</a>
                </p>
                {content.subjects ? (
                  <>
                    Tópicos:
                    <br></br>
                    {content.subjects.map((sub) => (
                      <Badge
                        key={sub.ASJC}
                        pill
                        bg="dark"
                        className="bagde-color-crossref"
                      >
                        {sub.name}
                      </Badge>
                    ))}
                  </>
                ) : null}
              </Col>
              <Col>
                {content.dois_by_issued_year ? (
                  <ChartsBarCrossref doisYear={content.dois_by_issued_year} />
                ) : null}
              </Col>
            </>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
}

export default CrossrefIssnDetails;
