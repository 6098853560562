import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import Search from "./components/Search";
import Container from "react-bootstrap/Container";
import "./theme.scss";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";
import FAQ from "./components/FAQ";
import ExportDataPage from "./components/ExportDataPage";
import AboutUs from "./components/AboutUs";
import useApiConfig from "./helpers/getConfig";

function App() {
  useApiConfig(); // Setea la configuracion de las apis externas/
  return (
    <>
      <Router>
        <div className="app-container">
          <Header />
          <Container fluid="lg" className="content">
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Search />
                    <Content />
                  </>
                }
              />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/export-data" element={<ExportDataPage />} />
            </Routes>
          </Container>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
