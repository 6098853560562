import Papa from "papaparse";

export const formatCsv = async (data, criteria) => {
  let newJsonArray = {};
  if (criteria === "DOI") {
    newJsonArray = formatDataDOI(data);
  } else {
    newJsonArray = formatDataISSN(data);
  } //  para ISSN

  const csv = Papa.unparse(newJsonArray);
  return csv;
};

export const formatJson = async (data, criteria) => {
  let newJsonArray = {};
  if (criteria === "DOI") {
    newJsonArray = formatDataDOI(data);
  } else {
    newJsonArray = formatDataISSN(data);
  } //
  return newJsonArray;
};

function formatDataISSN(data: any) {
  return data.results.map((result) => {
    let wosConcept = "";

    wosConcept = result.WosISSN?.categories
      ?.map((categorie) => categorie.productDescription)
      .join(",");
    // const doajIssn =
    //   result.issnData?.DoajISSN?.apc || result.issnData?.DoajISSN?.admin
    //     ? "Si" + (result.issnData?.DoajISSN?.seal ? " (Si)" : "")
    //     : "No";
    const doajIssn = result.DoajISSN?.admin ? 'Si' : 'No';
    const scopus_CiteScore = `${
      result.ScopusIssn?.citeScoreYearInfoList?.citeScoreCurrentMetric
        ? result.ScopusIssn?.citeScoreYearInfoList.citeScoreCurrentMetric
        : ""
    } ${
      result.ScopusIssn?.citeScoreYearInfoList?.citeScoreCurrentMetricYear
        ? `(${result.ScopusIssn?.citeScoreYearInfoList.citeScoreCurrentMetricYear})`
        : ""
    }`;

    const scopus_citeScoreTracker = `${
      result.ScopusIssn?.citeScoreYearInfoList?.citeScoreTracker
        ? result.ScopusIssn?.citeScoreYearInfoList?.citeScoreTracker
        : ""
    } ${
      result.ScopusIssn?.citeScoreYearInfoList?.citeScoreTrackerYear
        ? `(${result.ScopusIssn?.citeScoreYearInfoList?.citeScoreTrackerYear})`
        : ""
    }`;

    const sjr_best_quartile = result.ScimagoIssn?.quartile;
    const sjr_best_year_quartile = result.ScimagoIssn?.yearQuartile;
    const sjr_all_time_best_quartile = sjr_best_quartile
      ? `${sjr_best_quartile} (${sjr_best_year_quartile})`
      : "";

    return {
      issn: result.identifier?.value,
      type: result.type,
      title: result.title,
      publisher: result.publisher,
      openalex_issn_cites: result.OpenAlexISSN?.cited_by_count,
      crossref_dois: result.CrossrefISSN?.total_dois,
      doaj_issn_presence: doajIssn,
      redib_presence: "No",
      wos_collections: wosConcept,
      scopus_citescore: scopus_CiteScore,
      scopus_citescoretracker: scopus_citeScoreTracker,
      "sjr_h-index": result.ScimagoIssn?.hIndex,
      "sjr_all-time_best_quartile": sjr_all_time_best_quartile,
    };
  });
}

function formatDataDOI(data: any) {
  return data.results.map((result) => {
    let authorsName = "";
    let wosConcept = "";

    if (result.authors) {
      authorsName = result.authors.map((author) => author.name).join(",");
    }
    if (result.issnData) {
      wosConcept = result.issnData?.WosISSN?.categories
        ?.map((categorie) => categorie.productDescription)
        .join(",");
    }

    const doajDoi = result.DoajDOI.title
      ? "Si" + (result.DoajDOI.seal ? ` (Si)` : "")
      : "No";
    const doajIssn =
      result.issnData?.DoajISSN?.apc || result.issnData?.DoajISSN?.admin
        ? "Si" + (result.issnData?.DoajISSN?.seal ? " (Si)" : "")
        : "No";
    const scopus_CiteScore = `${
      result.issnData?.ScopusIssn?.citeScoreYearInfoList?.citeScoreCurrentMetric
        ? result.issnData.ScopusIssn.citeScoreYearInfoList
            .citeScoreCurrentMetric
        : ""
    } ${
      result.issnData?.ScopusIssn?.citeScoreYearInfoList
        ?.citeScoreCurrentMetricYear
        ? `(${result.issnData.ScopusIssn.citeScoreYearInfoList.citeScoreCurrentMetricYear})`
        : ""
    }`;

    const scopus_citeScoreTracker = `${
      result.issnData?.ScopusIssn?.citeScoreYearInfoList?.citeScoreTracker
        ? result.issnData.ScopusIssn.citeScoreYearInfoList.citeScoreTracker
        : ""
    } ${
      result.issnData?.ScopusIssn?.citeScoreYearInfoList?.citeScoreTrackerYear
        ? `(${result.issnData.ScopusIssn.citeScoreYearInfoList.citeScoreTrackerYear})`
        : ""
    }`;

    const sjr_best_quartile = result.issnData?.ScimagoIssn?.quartile;
    const sjr_best_year_quartile = result.issnData?.ScimagoIssn?.yearQuartile;
    const sjr_all_time_best_quartile = sjr_best_quartile
      ? `${sjr_best_quartile} (${sjr_best_year_quartile})`
      : "";

    return {
      doi: result.doi,
      type: result.type,
      title: result.title,
      authors: authorsName,
      abstract: result.abstract,
      publication_year: result.publication_year,
      crossref_cites: result.CrossrefDOI.cites,
      openalex_doi_cites: result.OpenAlexDOI.cited_by_count,
      doaj_doi_presence: doajDoi,
      semanticscholar_cites: result.SemanticScholar.numCitedBy,
      dimensions_cites: result.Dimensions.times_cited,
      altmetric_cites: result.Altmetric.cited_by_posts_count,
      issn: result.issn,
      journal_title: result.issnData?.title,
      publisher: result.issnData?.publisher,
      openalex_issn_cites: result.issnData?.OpenAlexISSN.cited_by_count,
      crossref_dois: result.issnData?.CrossrefISSN.total_dois,
      doaj_issn_presence: doajIssn,
      redib_presence: "No",
      wos_collections: wosConcept,
      scopus_citescore: scopus_CiteScore,
      scopus_citescoretracker: scopus_citeScoreTracker,
      "sjr_h-index": result.issnData?.ScimagoIssn?.hIndex,
      "sjr_all-time_best_quartile": sjr_all_time_best_quartile,
    };
  });
}
