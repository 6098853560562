import Card from "react-bootstrap/Card";
import ChartsBar from "../../ChartsBar";
import openAlexlogo from "../../../../../images/resize/OpenAlex-logo.png";
import magLogo from "../../../../../images/mag-logo.png";
import pubMed from "../../../../../images/pubmed-logo.png";
import { Col, Image, Row } from "react-bootstrap";

function OpenAlexDoiDetails({ content }) {
  const counts_by_year = content.counts_by_year ?? undefined;

  return (
    <>
      <Card
        className="tab-color-openalex-detail"
        bg="light"
        style={{ marginTop: "3rem" }}
      >
        <Card.Body>
          <Row>
            <Col xs={12} sm={2}>
              <a href={content.id} target="_blank" rel="noreferrer">
                <Image src={openAlexlogo} />{" "}
              </a>
            </Col>
            {content.notFound ? (
              <Col xs={4}>
                <p>{content.error}</p>
              </Col>
            ) : (
              <>
                {content.cited_by_count ? (
                  <Col xs={12} sm={6} style={{ alignItems: "center" }}>
                    <p style={{ fontSize: "1.2em" }}>
                      Cantidad de citas: <b>{content.cited_by_count}</b>
                    </p>
                    {counts_by_year && counts_by_year.length > 0 && (
                      <ChartsBar citedYear={counts_by_year} />
                    )}
                  </Col>
                ) : (
                  <Col xs={12} sm={4}>
                    <p>No se encontraron citas</p>
                  </Col>
                )}
                {content.ids && (
                  <>
                    <Col xs={12} sm={4}>
                      {content.ids.mag && (
                        <p style={{ fontSize: "1.2em" }}>
                          {" "}
                          <Image
                            src={magLogo}
                            style={{ width: "2em" }}
                          /> MAG: <b>{content.ids.mag}</b>
                        </p>
                      )}
                      {content.ids.pmid && (
                        <p>
                          {" "}
                          <a
                            href={content.ids.pmid}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            <Image src={pubMed} style={{ width: "7.5em" }} />
                          </a>
                        </p>
                      )}
                      {content.ids.pmcid && (
                        <p>
                          <a
                            style={{ width: "5em" }}
                            href={content.ids.pmcid}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {svgLogoNCBI}
                          </a>
                        </p>
                      )}
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default OpenAlexDoiDetails;

const svgLogoNCBI = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2039.55 243.26">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Text_outlines" data-name="Text outlines">
        <path
          className="cls-1"
          d="M266.77,67.24V176H248.18v-49.2H199.53V176H180.4V67.24H199v43.19h48.66V67.24ZM161.26,176H142.68V67.24h18.58Zm-37.17,0H104.41L55.76,96.21h-.55V176h-18V67.24H56.85l49.2,79.81h.55V67.24h18V176Zm208.83-54.66L266.77,0H21.87A22.05,22.05,0,0,0,0,22.41V220.85a22.05,22.05,0,0,0,21.87,22.41H266.22Z"
        />
        <path
          className="cls-1"
          d="M283.17,242.72l66.69-121.36L283.17,0h16.4c9.29,0,20.23,6.56,24.6,14.76l58.49,106.6L324.17,228c-4.37,8.2-15.31,14.76-24.6,14.76Z"
        />
        <path
          className="cls-1"
          d="M428.15,31.14h16.5L485.4,96.89h.25V31.14h14.88v89.25H484L443.4,54.76H443v65.63H428.15Z"
        />
        <path
          className="cls-1"
          d="M571.15,106a7.54,7.54,0,0,0,.69,3.75,2.9,2.9,0,0,0,2.69,1.13H576a9.16,9.16,0,0,0,2-.25v9.87c-.5.17-1.15.36-1.94.57s-1.61.39-2.44.56-1.67.29-2.5.37a20,20,0,0,1-2.12.13,13.78,13.78,0,0,1-7.25-1.75q-2.88-1.76-3.75-6.13a25.29,25.29,0,0,1-10.44,6,41.17,41.17,0,0,1-11.94,1.88,29.25,29.25,0,0,1-8.37-1.19,22,22,0,0,1-7.07-3.5,16.81,16.81,0,0,1-4.87-5.87,18.11,18.11,0,0,1-1.81-8.32,19.18,19.18,0,0,1,2.18-9.75,16.27,16.27,0,0,1,5.75-5.87,25.79,25.79,0,0,1,8-3.06,90.4,90.4,0,0,1,8.94-1.44,70.49,70.49,0,0,1,7.38-1.06A34.66,34.66,0,0,0,552,81a9.49,9.49,0,0,0,4.25-2.31A6.37,6.37,0,0,0,557.78,74a7.28,7.28,0,0,0-1.32-4.5,8.78,8.78,0,0,0-3.25-2.68,13.68,13.68,0,0,0-4.31-1.25,34.25,34.25,0,0,0-4.5-.32,17.93,17.93,0,0,0-9.87,2.5,9.74,9.74,0,0,0-4.38,7.75H515.9a21.45,21.45,0,0,1,3-10.37,20.76,20.76,0,0,1,6.69-6.63,27.57,27.57,0,0,1,9.19-3.5,55.13,55.13,0,0,1,10.5-1,44.65,44.65,0,0,1,9.37,1A26.74,26.74,0,0,1,563,58.26a18,18,0,0,1,5.94,5.82,15.93,15.93,0,0,1,2.25,8.68ZM556.9,88a17.36,17.36,0,0,1-8,2.57c-3.17.29-6.33.73-9.5,1.31A30,30,0,0,0,535,93a12.93,12.93,0,0,0-3.75,1.88A8.22,8.22,0,0,0,528.71,98a10.78,10.78,0,0,0-.93,4.69,6,6,0,0,0,1.37,4,10,10,0,0,0,3.31,2.56,15.17,15.17,0,0,0,4.25,1.31,26.44,26.44,0,0,0,4.19.38,23.14,23.14,0,0,0,5.13-.63,16.81,16.81,0,0,0,5.18-2.12,13.72,13.72,0,0,0,4.07-3.81,9.69,9.69,0,0,0,1.62-5.69Z"
        />
        <path
          className="cls-1"
          d="M580,55.76h10.75V36.39H605V55.76H617.9V66.39H605v34.5a35.69,35.69,0,0,0,.18,3.87,6.71,6.71,0,0,0,.88,2.75,4.15,4.15,0,0,0,2.06,1.69,10.07,10.07,0,0,0,3.75.56q1.5,0,3-.06a14.79,14.79,0,0,0,3-.44v11l-4.62.5a41.2,41.2,0,0,1-4.63.25,31.47,31.47,0,0,1-9.06-1.06,12.17,12.17,0,0,1-5.38-3.12,10.85,10.85,0,0,1-2.62-5.19,38.6,38.6,0,0,1-.81-7.13V66.39H580Z"
        />
        <path
          className="cls-1"
          d="M628.52,31.14h14.25v13.5H628.52Zm0,24.62h14.25v64.63H628.52Z"
        />
        <path
          className="cls-1"
          d="M687.9,122.14a35.31,35.31,0,0,1-13.81-2.56,29.4,29.4,0,0,1-10.25-7.07,30.09,30.09,0,0,1-6.38-10.75,43.85,43.85,0,0,1,0-27.37,30.09,30.09,0,0,1,6.38-10.75,29.25,29.25,0,0,1,10.25-7.06,38.39,38.39,0,0,1,27.62,0A29.34,29.34,0,0,1,712,63.64a30.24,30.24,0,0,1,6.38,10.75,44,44,0,0,1,0,27.37A30.24,30.24,0,0,1,712,112.51a29.5,29.5,0,0,1-10.25,7.07A35.31,35.31,0,0,1,687.9,122.14Zm0-11.25a16.37,16.37,0,0,0,8.25-2,17.35,17.35,0,0,0,5.75-5.25,23,23,0,0,0,3.31-7.31,32.91,32.91,0,0,0,0-16.57,22.18,22.18,0,0,0-3.31-7.31,17.83,17.83,0,0,0-5.75-5.19,18,18,0,0,0-16.5,0,17.83,17.83,0,0,0-5.75,5.19,22,22,0,0,0-3.31,7.31,32.61,32.61,0,0,0,0,16.57,22.83,22.83,0,0,0,3.31,7.31,17.35,17.35,0,0,0,5.75,5.25A16.37,16.37,0,0,0,687.9,110.89Z"
        />
        <path
          className="cls-1"
          d="M732.4,55.76h13.5v9.5l.25.25a23.83,23.83,0,0,1,8.5-8.43A22.56,22.56,0,0,1,766.27,54q10.62,0,16.75,5.5T789.15,76v44.38H774.9V79.76q-.25-7.62-3.25-11.06t-9.38-3.44a15.41,15.41,0,0,0-6.5,1.32,14,14,0,0,0-4.87,3.62,17,17,0,0,0-3.13,5.44,19.38,19.38,0,0,0-1.12,6.62v38.13H732.4Z"
        />
        <path
          className="cls-1"
          d="M858.27,106a7.54,7.54,0,0,0,.69,3.75,2.9,2.9,0,0,0,2.69,1.13h1.5a9.16,9.16,0,0,0,2-.25v9.87c-.5.17-1.15.36-1.94.57s-1.61.39-2.44.56-1.67.29-2.5.37a20,20,0,0,1-2.12.13,13.78,13.78,0,0,1-7.25-1.75q-2.88-1.76-3.75-6.13a25.29,25.29,0,0,1-10.44,6,41.17,41.17,0,0,1-11.94,1.88A29.25,29.25,0,0,1,814.4,121a22.07,22.07,0,0,1-7.07-3.5,16.81,16.81,0,0,1-4.87-5.87,18.11,18.11,0,0,1-1.81-8.32,19.29,19.29,0,0,1,2.18-9.75,16.27,16.27,0,0,1,5.75-5.87,25.89,25.89,0,0,1,8-3.06,90.4,90.4,0,0,1,8.94-1.44,70.49,70.49,0,0,1,7.38-1.06A34.66,34.66,0,0,0,839.08,81a9.43,9.43,0,0,0,4.25-2.31A6.33,6.33,0,0,0,844.9,74a7.28,7.28,0,0,0-1.32-4.5,8.7,8.7,0,0,0-3.25-2.68A13.68,13.68,0,0,0,836,65.58a34.25,34.25,0,0,0-4.5-.32,17.93,17.93,0,0,0-9.87,2.5,9.74,9.74,0,0,0-4.38,7.75H803a21.45,21.45,0,0,1,3-10.37,20.76,20.76,0,0,1,6.69-6.63A27.57,27.57,0,0,1,821.9,55a55.13,55.13,0,0,1,10.5-1,44.65,44.65,0,0,1,9.37,1,26.65,26.65,0,0,1,8.31,3.25A18,18,0,0,1,856,64.08a15.93,15.93,0,0,1,2.25,8.68ZM844,88a17.36,17.36,0,0,1-8,2.57c-3.17.29-6.33.73-9.5,1.31A30,30,0,0,0,822.15,93a12.93,12.93,0,0,0-3.75,1.88A8.22,8.22,0,0,0,815.83,98a10.78,10.78,0,0,0-.93,4.69,6,6,0,0,0,1.37,4,10,10,0,0,0,3.31,2.56,15.27,15.27,0,0,0,4.25,1.31,26.55,26.55,0,0,0,4.19.38,23.14,23.14,0,0,0,5.13-.63,16.81,16.81,0,0,0,5.18-2.12,13.72,13.72,0,0,0,4.07-3.81A9.69,9.69,0,0,0,844,98.64Z"
        />
        <path className="cls-1" d="M874,31.14h14.25v89.25H874Z" />
        <path className="cls-1" d="M940.52,31.14h15.63v75.75h45.37v13.5h-61Z" />
        <path
          className="cls-1"
          d="M1010.65,31.14h14.25v13.5h-14.25Zm0,24.62h14.25v64.63h-14.25Z"
        />
        <path
          className="cls-1"
          d="M1040.77,31.14H1055v33h.25a19.61,19.61,0,0,1,3.63-4.25,22.61,22.61,0,0,1,4.68-3.19,24.84,24.84,0,0,1,5.38-2,23.25,23.25,0,0,1,5.56-.69,30.88,30.88,0,0,1,13.31,2.69,26.21,26.21,0,0,1,9.44,7.38A32,32,0,0,1,1102.9,75a46.38,46.38,0,0,1,1.87,13.38,47.08,47.08,0,0,1-1.69,12.62,32.79,32.79,0,0,1-5.12,10.82,25,25,0,0,1-21.06,10.31,50.47,50.47,0,0,1-6.57-.44A24.4,24.4,0,0,1,1064,120a21.45,21.45,0,0,1-5.44-3.25,15.76,15.76,0,0,1-4.06-5.25h-.25v8.88h-13.5Zm49.75,57a32.14,32.14,0,0,0-1.12-8.5,23,23,0,0,0-3.38-7.31,17.35,17.35,0,0,0-5.62-5.13,15.32,15.32,0,0,0-7.75-1.94q-9,0-13.57,6.25t-4.56,16.63a33.14,33.14,0,0,0,1.19,9.06,20.45,20.45,0,0,0,3.56,7.19,17.79,17.79,0,0,0,5.69,4.75,16.19,16.19,0,0,0,7.69,1.75,16,16,0,0,0,8.25-2,17.37,17.37,0,0,0,5.56-5.19,20.85,20.85,0,0,0,3.12-7.25A36.57,36.57,0,0,0,1090.52,88.14Z"
        />
        <path
          className="cls-1"
          d="M1116.77,55.76h13.37v12.5h.25a14.47,14.47,0,0,1,2.44-5.12,23.55,23.55,0,0,1,4.38-4.56,22.05,22.05,0,0,1,5.68-3.32,17.13,17.13,0,0,1,6.38-1.25,31.47,31.47,0,0,1,3.44.13l1.93.25V68.14c-1-.17-2-.31-3.06-.44a26.73,26.73,0,0,0-3.06-.19,16.32,16.32,0,0,0-6.81,1.44,15.76,15.76,0,0,0-5.57,4.25,21,21,0,0,0-3.75,6.94,30.24,30.24,0,0,0-1.37,9.5v30.75h-14.25Z"
        />
        <path
          className="cls-1"
          d="M1214.89,106a7.54,7.54,0,0,0,.69,3.75,2.9,2.9,0,0,0,2.69,1.13h1.5a9.16,9.16,0,0,0,2-.25v9.87c-.5.17-1.15.36-1.94.57s-1.6.39-2.44.56-1.66.29-2.5.37a20,20,0,0,1-2.12.13,13.78,13.78,0,0,1-7.25-1.75q-2.88-1.76-3.75-6.13a25.22,25.22,0,0,1-10.44,6,41.07,41.07,0,0,1-11.94,1.88A29.3,29.3,0,0,1,1171,121a21.91,21.91,0,0,1-7.06-3.5,16.73,16.73,0,0,1-4.88-5.87,18.11,18.11,0,0,1-1.81-8.32,19.18,19.18,0,0,1,2.19-9.75,16.11,16.11,0,0,1,5.75-5.87,25.69,25.69,0,0,1,8-3.06,89.73,89.73,0,0,1,8.93-1.44,70.87,70.87,0,0,1,7.38-1.06,34.94,34.94,0,0,0,6.19-1.07A9.54,9.54,0,0,0,1200,78.7a6.37,6.37,0,0,0,1.56-4.69,7.34,7.34,0,0,0-1.31-4.5,8.86,8.86,0,0,0-3.25-2.68,13.74,13.74,0,0,0-4.32-1.25,34.25,34.25,0,0,0-4.5-.32,17.93,17.93,0,0,0-9.87,2.5,9.76,9.76,0,0,0-4.38,7.75h-14.25a21.45,21.45,0,0,1,3-10.37,20.76,20.76,0,0,1,6.69-6.63,27.57,27.57,0,0,1,9.19-3.5,55.13,55.13,0,0,1,10.5-1,44.65,44.65,0,0,1,9.37,1,26.71,26.71,0,0,1,8.32,3.25,18,18,0,0,1,5.93,5.82,15.93,15.93,0,0,1,2.25,8.68Zm-14.25-18a17.32,17.32,0,0,1-8,2.57q-4.74.44-9.5,1.31a30,30,0,0,0-4.37,1.06,12.93,12.93,0,0,0-3.75,1.88,8.1,8.1,0,0,0-2.56,3.12,10.63,10.63,0,0,0-.94,4.69,6,6,0,0,0,1.37,4,9.94,9.94,0,0,0,3.32,2.56,15.07,15.07,0,0,0,4.25,1.31,26.33,26.33,0,0,0,4.18.38,23.07,23.07,0,0,0,5.13-.63,17,17,0,0,0,5.19-2.12,13.8,13.8,0,0,0,4.06-3.81,9.69,9.69,0,0,0,1.62-5.69Z"
        />
        <path
          className="cls-1"
          d="M1230.27,55.76h13.37v12.5h.25a14.47,14.47,0,0,1,2.44-5.12,23.55,23.55,0,0,1,4.38-4.56,22.05,22.05,0,0,1,5.68-3.32,17.13,17.13,0,0,1,6.38-1.25,31.6,31.6,0,0,1,3.44.13l1.93.25V68.14c-1-.17-2-.31-3.06-.44a26.73,26.73,0,0,0-3.06-.19,16.32,16.32,0,0,0-6.81,1.44,15.76,15.76,0,0,0-5.57,4.25,21,21,0,0,0-3.75,6.94,30.24,30.24,0,0,0-1.37,9.5v30.75h-14.25Z"
        />
        <path
          className="cls-1"
          d="M1267.14,55.76h15.63L1299.64,104h.25l16.38-48.25h14.87L1306,123.89c-1.17,2.91-2.31,5.71-3.44,8.37a30.11,30.11,0,0,1-4.12,7.07,17.89,17.89,0,0,1-6.07,4.87,20.62,20.62,0,0,1-9.25,1.81,64.81,64.81,0,0,1-9.87-.75v-12c1.16.17,2.29.36,3.37.57a18.63,18.63,0,0,0,3.38.31,11.51,11.51,0,0,0,4.12-.63,6.74,6.74,0,0,0,2.69-1.81,11.22,11.22,0,0,0,1.81-2.81,28.64,28.64,0,0,0,1.38-3.75l1.62-5Z"
        />
        <path
          className="cls-1"
          d="M1403.52,122.14a35.31,35.31,0,0,1-13.81-2.56,29.4,29.4,0,0,1-10.25-7.07,30.09,30.09,0,0,1-6.38-10.75,43.85,43.85,0,0,1,0-27.37,30.09,30.09,0,0,1,6.38-10.75,29.25,29.25,0,0,1,10.25-7.06,38.39,38.39,0,0,1,27.62,0,29.34,29.34,0,0,1,10.25,7.06A30.24,30.24,0,0,1,1434,74.39a44,44,0,0,1,0,27.37,30.24,30.24,0,0,1-6.38,10.75,29.5,29.5,0,0,1-10.25,7.07A35.35,35.35,0,0,1,1403.52,122.14Zm0-11.25a16.42,16.42,0,0,0,8.25-2,17.35,17.35,0,0,0,5.75-5.25,23,23,0,0,0,3.31-7.31,32.91,32.91,0,0,0,0-16.57,22.18,22.18,0,0,0-3.31-7.31,17.83,17.83,0,0,0-5.75-5.19,18,18,0,0,0-16.5,0,17.73,17.73,0,0,0-5.75,5.19,22,22,0,0,0-3.31,7.31,32.61,32.61,0,0,0,0,16.57,22.83,22.83,0,0,0,3.31,7.31,17.25,17.25,0,0,0,5.75,5.25A16.37,16.37,0,0,0,1403.52,110.89Z"
        />
        <path
          className="cls-1"
          d="M1441.64,55.76h10.63V50.39a27.59,27.59,0,0,1,1.5-9.94,14,14,0,0,1,4-5.87,13,13,0,0,1,5.68-2.75,31.62,31.62,0,0,1,6.69-.69,41,41,0,0,1,10,.87V43.14a19.9,19.9,0,0,0-2.94-.56,30.26,30.26,0,0,0-3.68-.19,8.73,8.73,0,0,0-4.94,1.37c-1.38.92-2.06,2.71-2.06,5.38v6.62h12.12V66.39h-12.12v54h-14.25v-54h-10.63Z"
        />
        <path
          className="cls-1"
          d="M1523.89,31.14h22L1570.51,101h.25l24-69.87h21.75v89.25h-14.87V51.51h-.25l-24.75,68.88h-12.88L1539,51.51h-.25v68.88h-14.87Z"
        />
        <path
          className="cls-1"
          d="M1644.39,91.76a24.75,24.75,0,0,0,1.06,7.25,17.8,17.8,0,0,0,3.19,6.13,15.45,15.45,0,0,0,5.37,4.19,17.77,17.77,0,0,0,7.75,1.56q6.26,0,10.07-2.69a15.89,15.89,0,0,0,5.68-8.06H1691a26.76,26.76,0,0,1-10.5,16.31,28.53,28.53,0,0,1-8.68,4.25,35,35,0,0,1-10.07,1.44,34.36,34.36,0,0,1-13.5-2.5,27.91,27.91,0,0,1-9.93-7,29.73,29.73,0,0,1-6.13-10.75,43.83,43.83,0,0,1-2.06-13.75,38.84,38.84,0,0,1,2.19-13.06,33.28,33.28,0,0,1,6.25-10.88A29.3,29.3,0,0,1,1661.39,54a29.07,29.07,0,0,1,13.69,3.19,30.14,30.14,0,0,1,10.06,8.44A34.3,34.3,0,0,1,1691,77.7a37,37,0,0,1,.94,14.06Zm33.25-9.37a23.13,23.13,0,0,0-1.44-6.5,17.23,17.23,0,0,0-3.25-5.44,16.07,16.07,0,0,0-5-3.75,14.72,14.72,0,0,0-6.56-1.44,17,17,0,0,0-6.81,1.32,15.36,15.36,0,0,0-5.25,3.62,17.84,17.84,0,0,0-3.5,5.44,18.76,18.76,0,0,0-1.44,6.75Z"
        />
        <path
          className="cls-1"
          d="M1763.64,120.39h-13.5v-8.75h-.25a16.91,16.91,0,0,1-8.38,8.06,28.26,28.26,0,0,1-11.62,2.44,30.85,30.85,0,0,1-13.31-2.69,26.5,26.5,0,0,1-9.44-7.31,31.27,31.27,0,0,1-5.63-10.94,47.43,47.43,0,0,1-1.87-13.56A43.46,43.46,0,0,1,1702,72.51a30.33,30.33,0,0,1,6.32-10.5,24.3,24.3,0,0,1,9-6.06A28.64,28.64,0,0,1,1727.64,54a34,34,0,0,1,6.12.57,26.68,26.68,0,0,1,6,1.81,23.33,23.33,0,0,1,5.32,3.19,17.84,17.84,0,0,1,4.06,4.56h.25v-33h14.25Zm-49.75-31.63a31.5,31.5,0,0,0,1.06,8.13,22.18,22.18,0,0,0,3.25,7.12,15.81,15.81,0,0,0,13.56,6.88,16,16,0,0,0,8.19-2,17,17,0,0,0,5.63-5.25,24.09,24.09,0,0,0,3.25-7.31,33.12,33.12,0,0,0,1.06-8.32q0-10.74-4.81-16.75a15.87,15.87,0,0,0-13.07-6,16.06,16.06,0,0,0-8.43,2.07A17.21,17.21,0,0,0,1718,72.7a22.21,22.21,0,0,0-3.12,7.5A38.78,38.78,0,0,0,1713.89,88.76Z"
        />
        <path
          className="cls-1"
          d="M1779.51,31.14h14.25v13.5h-14.25Zm0,24.62h14.25v64.63h-14.25Z"
        />
        <path
          className="cls-1"
          d="M1852.89,77.39q-.89-6-4.82-9.06a15.62,15.62,0,0,0-9.93-3.07,20.65,20.65,0,0,0-5.88.94,14,14,0,0,0-5.75,3.5,19.49,19.49,0,0,0-4.37,7.13,33.82,33.82,0,0,0-1.75,11.93,35.16,35.16,0,0,0,.93,8,22.05,22.05,0,0,0,3,7.13,16.3,16.3,0,0,0,5.32,5.06,15.1,15.1,0,0,0,7.87,1.94,14.29,14.29,0,0,0,10.31-3.88q4.07-3.87,5.07-10.87h14.25q-2,12.63-9.57,19.31t-20.06,6.69a33.22,33.22,0,0,1-13.44-2.56,28.68,28.68,0,0,1-9.81-7A29.59,29.59,0,0,1,1808.2,102a41.12,41.12,0,0,1-2.06-13.25,45,45,0,0,1,2-13.62,31.05,31.05,0,0,1,6-11.06,28,28,0,0,1,9.93-7.38A33.59,33.59,0,0,1,1838,54a38.93,38.93,0,0,1,10.69,1.44,28.42,28.42,0,0,1,9,4.31,23.17,23.17,0,0,1,6.44,7.25,25.06,25.06,0,0,1,3,10.38Z"
        />
        <path
          className="cls-1"
          d="M1879.14,31.14h14.25v13.5h-14.25Zm0,24.62h14.25v64.63h-14.25Z"
        />
        <path
          className="cls-1"
          d="M1908.89,55.76h13.5v9.5l.25.25a23.83,23.83,0,0,1,8.5-8.43A22.56,22.56,0,0,1,1942.76,54q10.62,0,16.75,5.5t6.13,16.5v44.38h-14.25V79.76q-.26-7.62-3.25-11.06t-9.38-3.44a15.41,15.41,0,0,0-6.5,1.32,14,14,0,0,0-4.87,3.62,17,17,0,0,0-3.13,5.44,19.38,19.38,0,0,0-1.12,6.62v38.13h-14.25Z"
        />
        <path
          className="cls-1"
          d="M1991.76,91.76a24.75,24.75,0,0,0,1.06,7.25,17.8,17.8,0,0,0,3.19,6.13,15.48,15.48,0,0,0,5.38,4.19,17.74,17.74,0,0,0,7.75,1.56q6.24,0,10.06-2.69a15.9,15.9,0,0,0,5.69-8.06h13.5a26.88,26.88,0,0,1-10.5,16.31,28.59,28.59,0,0,1-8.69,4.25,34.91,34.91,0,0,1-10.06,1.44,34.29,34.29,0,0,1-13.5-2.5,27.94,27.94,0,0,1-9.94-7,29.73,29.73,0,0,1-6.13-10.75,43.83,43.83,0,0,1-2.06-13.75,38.84,38.84,0,0,1,2.19-13.06A33.46,33.46,0,0,1,1986,64.2,29.3,29.3,0,0,1,2008.76,54a29,29,0,0,1,13.69,3.19,30.14,30.14,0,0,1,10.06,8.44,34.13,34.13,0,0,1,5.81,12.06,37,37,0,0,1,.94,14.06ZM2025,82.39a23.13,23.13,0,0,0-1.44-6.5,17.23,17.23,0,0,0-3.25-5.44,16.07,16.07,0,0,0-5-3.75,14.69,14.69,0,0,0-6.56-1.44,17,17,0,0,0-6.81,1.32,15.55,15.55,0,0,0-5.25,3.62,17.84,17.84,0,0,0-3.5,5.44,18.76,18.76,0,0,0-1.44,6.75Z"
        />
        <path
          className="cls-1"
          d="M438.78,164.56l19.12,44.33h.15L467,164.56h6.9l-11.33,53.55H454.9l-19-44h-.15l-9.08,44h-6.9l11.33-53.55Z"
        />
        <path
          className="cls-1"
          d="M479.39,185.45a14.1,14.1,0,0,1,3.79-3.94,15.83,15.83,0,0,1,5.1-2.32,23.15,23.15,0,0,1,5.92-.75,31.06,31.06,0,0,1,5.4.45,13.69,13.69,0,0,1,4.5,1.57,8.55,8.55,0,0,1,3.11,3,9.13,9.13,0,0,1,1.17,4.84,16.68,16.68,0,0,1-.42,3.56c-.27,1.27-.53,2.44-.78,3.49L504.7,206c-.15.8-.36,1.7-.64,2.7a11,11,0,0,0-.41,2.85,2.64,2.64,0,0,0,.53,1.88,1.82,1.82,0,0,0,1.35.52,3.12,3.12,0,0,0,1-.19l1-.33-1,4.72a12.34,12.34,0,0,1-1.92.56,8.91,8.91,0,0,1-2,.27,5.23,5.23,0,0,1-4-1.43,4.69,4.69,0,0,1-1.05-4l-.15-.15a13.35,13.35,0,0,1-5,4.12,18.69,18.69,0,0,1-8,1.43,17.94,17.94,0,0,1-4.62-.57,9.5,9.5,0,0,1-3.71-1.87,9.3,9.3,0,0,1-2.51-3.38,12.1,12.1,0,0,1-.94-5.06,10.71,10.71,0,0,1,1.84-6.67,11.57,11.57,0,0,1,4.69-3.64,23.14,23.14,0,0,1,6.3-1.69q3.45-.45,6.67-.75c1.7-.15,3.16-.31,4.39-.49a9.91,9.91,0,0,0,3-.86,3.81,3.81,0,0,0,1.76-1.72,7.06,7.06,0,0,0,.56-3.08,4.07,4.07,0,0,0-.75-2.55,5.39,5.39,0,0,0-1.87-1.54,8.85,8.85,0,0,0-2.52-.78,16.59,16.59,0,0,0-2.66-.23,17,17,0,0,0-3.56.38,10.28,10.28,0,0,0-3.19,1.23,8.35,8.35,0,0,0-2.4,2.18,7.13,7.13,0,0,0-1.27,3.19H477.4A13.45,13.45,0,0,1,479.39,185.45Zm1.8,26.59a8.86,8.86,0,0,0,4.84,1.35q7.58,0,10.87-5.1a12.73,12.73,0,0,0,2.14-4.73c.37-1.7.79-3.45,1.24-5.25h-.15a8.74,8.74,0,0,1-3.42,1.39c-1.37.27-2.83.49-4.38.64s-3.12.33-4.69.56A18.11,18.11,0,0,0,483.4,202a7.84,7.84,0,0,0-3.07,2.13,6,6,0,0,0-1.28,3.87A4.47,4.47,0,0,0,481.19,212Z"
        />
        <path
          className="cls-1"
          d="M536.65,179.34l-1.2,5.62h-7.72l-4.8,22.8c0,.45-.1.88-.15,1.28a10.18,10.18,0,0,0-.08,1.27,2.08,2.08,0,0,0,1.16,2.06,6,6,0,0,0,2.67.57,9.48,9.48,0,0,0,1.87-.19,17.19,17.19,0,0,0,1.8-.49l-1.12,5.85c-.75.1-1.5.19-2.25.26a21.2,21.2,0,0,1-2.25.12,20,20,0,0,1-3.38-.27,7.61,7.61,0,0,1-2.74-1,5.52,5.52,0,0,1-1.87-1.91,5.93,5.93,0,0,1-.71-3.08,9.92,9.92,0,0,1,.11-1.57c.07-.5.16-1.05.26-1.65l5.1-24.08h-6.52l1.2-5.62h6.52l2.4-11.48h6.38l-2.4,11.48Z"
        />
        <path
          className="cls-1"
          d="M550.08,179.34,542,218.11h-6.3l8-38.77Zm-5-7,1.65-7.8h6.45l-1.65,7.8Z"
        />
        <path
          className="cls-1"
          d="M555.36,193.66a23.64,23.64,0,0,1,4.28-7.8,21.21,21.21,0,0,1,6.82-5.4,20,20,0,0,1,9.11-2q7.88,0,12,4.08t4.13,12a29.67,29.67,0,0,1-1.43,9.15,23.82,23.82,0,0,1-4.2,7.83,20.66,20.66,0,0,1-6.75,5.44,19.69,19.69,0,0,1-9.07,2,18.72,18.72,0,0,1-6.86-1.2A14.26,14.26,0,0,1,555,209.3a18.17,18.17,0,0,1-1.12-6.49A29.11,29.11,0,0,1,555.36,193.66Zm7.62,16.8q2.68,2.92,7.94,2.93a10.78,10.78,0,0,0,6.19-1.8,16.48,16.48,0,0,0,4.5-4.54,21.36,21.36,0,0,0,2.78-6,22,22,0,0,0,.93-6.08,15.3,15.3,0,0,0-.59-4.27,9.33,9.33,0,0,0-1.88-3.49,9.12,9.12,0,0,0-3.19-2.33,11,11,0,0,0-4.54-.86,11.59,11.59,0,0,0-6.45,1.8A16.35,16.35,0,0,0,564,190.4a20.61,20.61,0,0,0-2.81,6,23.25,23.25,0,0,0-.94,6.3A10.89,10.89,0,0,0,563,210.46Z"
        />
        <path
          className="cls-1"
          d="M609.62,179.34l-1.2,5.85h.15a15.14,15.14,0,0,1,6.08-5.07,18.35,18.35,0,0,1,7.72-1.68c3.65,0,6.37.8,8.14,2.4s2.66,4.12,2.66,7.57a23.49,23.49,0,0,1-.3,3.3c-.2,1.35-.47,2.78-.82,4.28l-4.73,22.12H621l4.95-22.65c.05-.4.12-.86.22-1.39s.2-1.07.3-1.65.19-1.12.27-1.65a10.28,10.28,0,0,0,.11-1.31,4.59,4.59,0,0,0-1.95-4.09,8.44,8.44,0,0,0-4.8-1.31,11.67,11.67,0,0,0-5.21,1.28,14.73,14.73,0,0,0-4.39,3.22,10.53,10.53,0,0,0-2.7,3.64,26.79,26.79,0,0,0-1.58,4.46l-4.5,21.45h-6.3l8.1-38.77Z"
        />
        <path
          className="cls-1"
          d="M643.34,185.45a14.06,14.06,0,0,1,3.78-3.94,15.83,15.83,0,0,1,5.1-2.32,23.22,23.22,0,0,1,5.93-.75,31.06,31.06,0,0,1,5.4.45,13.69,13.69,0,0,1,4.5,1.57,8.55,8.55,0,0,1,3.11,3,9.13,9.13,0,0,1,1.16,4.84,16.64,16.64,0,0,1-.41,3.56c-.27,1.27-.54,2.44-.79,3.49L668.65,206c-.15.8-.36,1.7-.64,2.7a11,11,0,0,0-.41,2.85,2.64,2.64,0,0,0,.52,1.88,1.82,1.82,0,0,0,1.35.52,3.18,3.18,0,0,0,1-.19l1-.33-1,4.72a12.18,12.18,0,0,1-1.91.56,9,9,0,0,1-2,.27,5.21,5.21,0,0,1-4-1.43,4.69,4.69,0,0,1-1-4l-.15-.15a13.38,13.38,0,0,1-5,4.12,18.69,18.69,0,0,1-7.95,1.43,17.86,17.86,0,0,1-4.61-.57,9.5,9.5,0,0,1-3.71-1.87,9.3,9.3,0,0,1-2.51-3.38,12.1,12.1,0,0,1-.94-5.06,10.71,10.71,0,0,1,1.84-6.67,11.53,11.53,0,0,1,4.68-3.64,23.14,23.14,0,0,1,6.3-1.69q3.45-.45,6.68-.75c1.7-.15,3.16-.31,4.39-.49a9.84,9.84,0,0,0,3-.86,3.84,3.84,0,0,0,1.77-1.72,7.06,7.06,0,0,0,.56-3.08,4.07,4.07,0,0,0-.75-2.55,5.43,5.43,0,0,0-1.88-1.54,8.78,8.78,0,0,0-2.51-.78,16.59,16.59,0,0,0-2.66-.23,17,17,0,0,0-3.56.38,10.28,10.28,0,0,0-3.19,1.23,8.35,8.35,0,0,0-2.4,2.18,7.14,7.14,0,0,0-1.28,3.19h-6.37A13.45,13.45,0,0,1,643.34,185.45Zm1.8,26.59a8.83,8.83,0,0,0,4.83,1.35q7.57,0,10.88-5.1a12.73,12.73,0,0,0,2.14-4.73q.56-2.55,1.23-5.25h-.15a8.69,8.69,0,0,1-3.41,1.39c-1.37.27-2.84.49-4.39.64s-3.11.33-4.68.56a18.11,18.11,0,0,0-4.24,1.09,7.88,7.88,0,0,0-3.08,2.13A6,6,0,0,0,643,208,4.47,4.47,0,0,0,645.14,212Z"
        />
        <path
          className="cls-1"
          d="M693.55,164.56,682.3,218.11H676l11.17-53.55Z"
        />
        <path
          className="cls-1"
          d="M755.39,214.17q-5.89,5.15-15.79,5.14-11,0-17-6.11t-6-17.29a37.86,37.86,0,0,1,2-12.41,32.13,32.13,0,0,1,5.82-10.39,27.51,27.51,0,0,1,21.56-9.82,26.66,26.66,0,0,1,8.06,1.16,18.43,18.43,0,0,1,6.45,3.45,15.87,15.87,0,0,1,4.31,5.77,19.64,19.64,0,0,1,1.58,8.14h-7.13q0-6.3-3.71-9.41a14.34,14.34,0,0,0-9.56-3.11,19.47,19.47,0,0,0-9.79,2.36,22.07,22.07,0,0,0-6.94,6.11,26.89,26.89,0,0,0-4.16,8.51,34.16,34.16,0,0,0-1.39,9.64,24.58,24.58,0,0,0,1,7.13,15.14,15.14,0,0,0,2.92,5.51,13.23,13.23,0,0,0,4.92,3.56,17.62,17.62,0,0,0,7,1.28q6.6,0,10.72-3.49a19,19,0,0,0,5.85-9.86h7.13Q761.28,209,755.39,214.17Z"
        />
        <path
          className="cls-1"
          d="M775.07,204a9.33,9.33,0,0,0,.83,4,8.21,8.21,0,0,0,2.25,2.93,10.91,10.91,0,0,0,3.22,1.8,11.28,11.28,0,0,0,3.75.64,12.12,12.12,0,0,0,7-1.92A11.25,11.25,0,0,0,796.3,206h6.37a16.72,16.72,0,0,1-2.4,5.66,15.16,15.16,0,0,1-4,4,17.5,17.5,0,0,1-5.18,2.44,21.47,21.47,0,0,1-6,.83,19.1,19.1,0,0,1-8-1.47,13.82,13.82,0,0,1-5.06-3.75,13.45,13.45,0,0,1-2.62-5,20,20,0,0,1-.75-5.29,30.46,30.46,0,0,1,1.61-10.16,25.1,25.1,0,0,1,4.35-7.91,19.42,19.42,0,0,1,6.37-5.1,17,17,0,0,1,7.62-1.8q8.55,0,12.48,4.5t3.94,12.67a17.34,17.34,0,0,1-.22,2.85c-.15.9-.28,1.63-.38,2.18H775.3A24.88,24.88,0,0,0,775.07,204Zm23.36-13a9.17,9.17,0,0,0-1.5-3.53,7.9,7.9,0,0,0-2.88-2.47,9.45,9.45,0,0,0-4.43-.94,13.78,13.78,0,0,0-5,.86,12.5,12.5,0,0,0-3.86,2.37,14,14,0,0,0-2.77,3.48,20.71,20.71,0,0,0-1.8,4.24h22.5A14.2,14.2,0,0,0,798.43,191Z"
        />
        <path
          className="cls-1"
          d="M822.17,179.34l-1.2,5.85h.15a15.14,15.14,0,0,1,6.08-5.07,18.31,18.31,0,0,1,7.72-1.68q5.48,0,8.14,2.4t2.66,7.57a23.49,23.49,0,0,1-.3,3.3c-.2,1.35-.48,2.78-.82,4.28l-4.73,22.12H833.5l5-22.65c0-.4.12-.86.22-1.39s.2-1.07.3-1.65.19-1.12.26-1.65a8.91,8.91,0,0,0,.12-1.31,4.59,4.59,0,0,0-2-4.09,8.44,8.44,0,0,0-4.8-1.31,11.75,11.75,0,0,0-5.22,1.28,14.81,14.81,0,0,0-4.38,3.22,10.41,10.41,0,0,0-2.7,3.64,26.79,26.79,0,0,0-1.58,4.46l-4.5,21.45h-6.3l8.1-38.77Z"
        />
        <path
          className="cls-1"
          d="M874.22,179.34,873,185H865.3l-4.8,22.8c0,.45-.11.88-.15,1.28a10.18,10.18,0,0,0-.08,1.27,2.08,2.08,0,0,0,1.16,2.06,6,6,0,0,0,2.67.57,9.48,9.48,0,0,0,1.87-.19,17.19,17.19,0,0,0,1.8-.49l-1.12,5.85c-.75.1-1.5.19-2.25.26a21.2,21.2,0,0,1-2.25.12,20,20,0,0,1-3.38-.27,7.61,7.61,0,0,1-2.74-1,5.52,5.52,0,0,1-1.87-1.91,5.93,5.93,0,0,1-.71-3.08,9.92,9.92,0,0,1,.11-1.57c.07-.5.16-1.05.26-1.65l5.1-24.08H852.4l1.2-5.62h6.52l2.4-11.48h6.38l-2.4,11.48Z"
        />
        <path
          className="cls-1"
          d="M880.67,204a9.19,9.19,0,0,0,.83,4,8.21,8.21,0,0,0,2.25,2.93,10.79,10.79,0,0,0,3.22,1.8,11.28,11.28,0,0,0,3.75.64,12.12,12.12,0,0,0,7-1.92,11.17,11.17,0,0,0,4.12-5.51h6.38a16.72,16.72,0,0,1-2.4,5.66,15.2,15.2,0,0,1-4,4,17.33,17.33,0,0,1-5.17,2.44,21.47,21.47,0,0,1-6,.83,19.1,19.1,0,0,1-8-1.47,13.82,13.82,0,0,1-5.06-3.75,13.3,13.3,0,0,1-2.62-5,20,20,0,0,1-.75-5.29,30.46,30.46,0,0,1,1.61-10.16,25.1,25.1,0,0,1,4.35-7.91,19.42,19.42,0,0,1,6.37-5.1,17,17,0,0,1,7.62-1.8q8.55,0,12.48,4.5t3.94,12.67a17.34,17.34,0,0,1-.22,2.85c-.15.9-.28,1.63-.38,2.18H880.89A26.62,26.62,0,0,0,880.67,204ZM904,191a9.17,9.17,0,0,0-1.5-3.53,7.85,7.85,0,0,0-2.89-2.47,9.38,9.38,0,0,0-4.42-.94,13.78,13.78,0,0,0-5,.86,12.5,12.5,0,0,0-3.86,2.37,14,14,0,0,0-2.77,3.48,20.71,20.71,0,0,0-1.8,4.24h22.5A14.58,14.58,0,0,0,904,191Z"
        />
        <path
          className="cls-1"
          d="M927.54,179.34l-1.87,8.32h.15a21.18,21.18,0,0,1,5.74-6.6,13.49,13.49,0,0,1,8.36-2.62,7.87,7.87,0,0,0,1.12-.08,3,3,0,0,1,1.13.08l-1.5,6.82a4.6,4.6,0,0,0-1-.07h-.9c-.8,0-1.45,0-1.94,0a9.68,9.68,0,0,0-1.88.34,12.38,12.38,0,0,0-4.69,2.33,16.56,16.56,0,0,0-3.56,4,15.72,15.72,0,0,0-2,4.39c-.5,1.67-.9,3.24-1.2,4.69l-3.67,17.17h-6.3l8-38.77Z"
        />
        <path
          className="cls-1"
          d="M962,185l1.12-5.62h6.6c.5-2,1-3.85,1.39-5.7a18.7,18.7,0,0,1,1.84-4.92,9.43,9.43,0,0,1,3.34-3.45,11.43,11.43,0,0,1,6-1.31c.9,0,1.8,0,2.7.11a13.47,13.47,0,0,1,2.62.49l-1,5.4c-.55-.1-1.07-.19-1.57-.26a9,9,0,0,0-1.43-.11,6.35,6.35,0,0,0-3.6.86,5.82,5.82,0,0,0-2,2.25,11.28,11.28,0,0,0-1,3.11c-.23,1.15-.52,2.33-.87,3.53h7.43l-1,5.62h-7.5l-7.13,33.15h-6.37l7.2-33.15Z"
        />
        <path
          className="cls-1"
          d="M984.58,193.66a23.64,23.64,0,0,1,4.28-7.8,21.21,21.21,0,0,1,6.82-5.4,19.92,19.92,0,0,1,9.11-2q7.88,0,12,4.08t4.13,12a29.67,29.67,0,0,1-1.43,9.15,23.82,23.82,0,0,1-4.2,7.83,20.66,20.66,0,0,1-6.75,5.44,19.69,19.69,0,0,1-9.07,2,18.72,18.72,0,0,1-6.86-1.2,14.26,14.26,0,0,1-8.37-8.44,18.17,18.17,0,0,1-1.12-6.49A29.11,29.11,0,0,1,984.58,193.66Zm7.61,16.8q2.7,2.92,7.95,2.93a10.78,10.78,0,0,0,6.19-1.8,16.76,16.76,0,0,0,4.5-4.54,21.36,21.36,0,0,0,2.78-6,22,22,0,0,0,.93-6.08,14.94,14.94,0,0,0-.6-4.27,8.9,8.9,0,0,0-5.06-5.82,11,11,0,0,0-4.54-.86,11.59,11.59,0,0,0-6.45,1.8,16.35,16.35,0,0,0-4.65,4.54,20.61,20.61,0,0,0-2.81,6,23.25,23.25,0,0,0-.94,6.3A10.93,10.93,0,0,0,992.19,210.46Z"
        />
        <path
          className="cls-1"
          d="M1038.62,179.34l-1.88,8.32h.15a21.18,21.18,0,0,1,5.74-6.6,13.49,13.49,0,0,1,8.36-2.62,8,8,0,0,0,1.13-.08,2.9,2.9,0,0,1,1.12.08l-1.5,6.82a4.49,4.49,0,0,0-1-.07h-.9q-1.2,0-1.95,0a9.82,9.82,0,0,0-1.88.34,12.38,12.38,0,0,0-4.69,2.33,16.56,16.56,0,0,0-3.56,4,15.71,15.71,0,0,0-2,4.39c-.5,1.67-.9,3.24-1.2,4.69l-3.68,17.17h-6.3l8-38.77Z"
        />
        <path
          className="cls-1"
          d="M1103.94,164.56q7.88,0,11.4,3.38t3.53,9.45a14.08,14.08,0,0,1-2.74,8.28,12.49,12.49,0,0,1-3.19,3,10.55,10.55,0,0,1-4.27,1.62v.15a10.83,10.83,0,0,1,5.85,3.93,11.59,11.59,0,0,1,2.17,7.17,15.94,15.94,0,0,1-1.46,6.9,15.23,15.23,0,0,1-4,5.21,19.06,19.06,0,0,1-6,3.3,23.21,23.21,0,0,1-7.42,1.16h-26.48l11.18-53.55Zm-7.35,47.55a31.91,31.91,0,0,0,3.53-.26,12.71,12.71,0,0,0,4.27-1.35,10.58,10.58,0,0,0,3.64-3.19,9.62,9.62,0,0,0,1.54-5.77,8.29,8.29,0,0,0-.9-4.13,6.12,6.12,0,0,0-2.44-2.4,10.84,10.84,0,0,0-3.53-1.12,27.79,27.79,0,0,0-4.16-.3h-15.3l-3.9,18.52Zm3.75-24.52q11.4,0,11.4-10.2a6.17,6.17,0,0,0-1-3.75,6.31,6.31,0,0,0-2.59-2,11.47,11.47,0,0,0-3.41-.86,33.17,33.17,0,0,0-3.49-.19H1088l-3.6,17Z"
        />
        <path
          className="cls-1"
          d="M1136.19,179.34l-8.1,38.77h-6.3l8-38.77Zm-5-7,1.65-7.8h6.45l-1.65,7.8Z"
        />
        <path
          className="cls-1"
          d="M1141.48,193.66a23.62,23.62,0,0,1,4.27-7.8,21.24,21.24,0,0,1,6.83-5.4,19.92,19.92,0,0,1,9.11-2q7.88,0,12,4.08t4.12,12a29.66,29.66,0,0,1-1.42,9.15,23.82,23.82,0,0,1-4.2,7.83,20.66,20.66,0,0,1-6.75,5.44,19.74,19.74,0,0,1-9.08,2,18.76,18.76,0,0,1-6.86-1.2,14.21,14.21,0,0,1-8.36-8.44,18.17,18.17,0,0,1-1.12-6.49A28.83,28.83,0,0,1,1141.48,193.66Zm7.61,16.8q2.7,2.92,8,2.93a10.8,10.8,0,0,0,6.19-1.8,16.76,16.76,0,0,0,4.5-4.54,21.33,21.33,0,0,0,2.77-6,21.66,21.66,0,0,0,.94-6.08,14.94,14.94,0,0,0-.6-4.27,9,9,0,0,0-5.06-5.82,11.08,11.08,0,0,0-4.54-.86,11.59,11.59,0,0,0-6.45,1.8,16.35,16.35,0,0,0-4.65,4.54,20.36,20.36,0,0,0-2.81,6,22.87,22.87,0,0,0-.94,6.3A10.93,10.93,0,0,0,1149.09,210.46Z"
        />
        <path
          className="cls-1"
          d="M1206.09,179.34l-1.2,5.62h-7.73l-4.8,22.8c0,.45-.1.88-.15,1.28a10.24,10.24,0,0,0-.07,1.27,2.07,2.07,0,0,0,1.16,2.06,6,6,0,0,0,2.66.57,9.59,9.59,0,0,0,1.88-.19,17.19,17.19,0,0,0,1.8-.49l-1.13,5.85c-.75.1-1.5.19-2.25.26a21.2,21.2,0,0,1-2.25.12,19.92,19.92,0,0,1-3.37-.27,7.61,7.61,0,0,1-2.74-1,5.52,5.52,0,0,1-1.87-1.91,5.93,5.93,0,0,1-.72-3.08,9.9,9.9,0,0,1,.12-1.57c.07-.5.16-1.05.26-1.65l5.1-24.08h-6.53l1.2-5.62H1192l2.4-11.48h6.37l-2.4,11.48Z"
        />
        <path
          className="cls-1"
          d="M1212.54,204a9.33,9.33,0,0,0,.82,4,8.21,8.21,0,0,0,2.25,2.93,11,11,0,0,0,3.23,1.8,11.28,11.28,0,0,0,3.75.64,12.12,12.12,0,0,0,7.05-1.92,11.23,11.23,0,0,0,4.12-5.51h6.38a16.72,16.72,0,0,1-2.4,5.66,15.2,15.2,0,0,1-4,4,17.44,17.44,0,0,1-5.17,2.44,21.47,21.47,0,0,1-6,.83,19.1,19.1,0,0,1-8-1.47,13.73,13.73,0,0,1-5.06-3.75,13.47,13.47,0,0,1-2.63-5,20,20,0,0,1-.75-5.29,30.71,30.71,0,0,1,1.61-10.16,25.31,25.31,0,0,1,4.36-7.91,19.32,19.32,0,0,1,6.37-5.1,16.93,16.93,0,0,1,7.61-1.8q8.55,0,12.49,4.5t3.94,12.67a17.32,17.32,0,0,1-.23,2.85c-.15.9-.27,1.63-.37,2.18h-29.18A24.88,24.88,0,0,0,1212.54,204Zm23.36-13a9.48,9.48,0,0,0-1.5-3.53,8,8,0,0,0-2.89-2.47,9.41,9.41,0,0,0-4.42-.94,13.74,13.74,0,0,0-5,.86,12.38,12.38,0,0,0-3.86,2.37,14.06,14.06,0,0,0-2.78,3.48,20.71,20.71,0,0,0-1.8,4.24h22.5A14.57,14.57,0,0,0,1235.9,191Z"
        />
        <path
          className="cls-1"
          d="M1274.71,186.05a8.54,8.54,0,0,0-6-2,12.6,12.6,0,0,0-6.49,1.65,15.46,15.46,0,0,0-4.72,4.28,19.2,19.2,0,0,0-2.89,5.88,22.39,22.39,0,0,0-1,6.42,18.44,18.44,0,0,0,.48,4.27,9.62,9.62,0,0,0,1.62,3.53,8,8,0,0,0,3,2.4,10.26,10.26,0,0,0,4.54.9,11.49,11.49,0,0,0,7-2.18,12.45,12.45,0,0,0,4.27-5.77h6.38a17.68,17.68,0,0,1-6.3,10.05,18.39,18.39,0,0,1-11.4,3.45q-8,0-12-4.35t-4-12.3a28.61,28.61,0,0,1,1.39-8.93,23.07,23.07,0,0,1,4.12-7.61,19.9,19.9,0,0,1,15.94-7.31q6.6,0,10.46,3.18t4.09,10h-6.37A7.19,7.19,0,0,0,1274.71,186.05Z"
        />
        <path
          className="cls-1"
          d="M1303.29,164.56,1298.71,185l.15.15a15.14,15.14,0,0,1,6.08-5.07,18.35,18.35,0,0,1,7.72-1.68q5.47,0,8.14,2.4t2.66,7.57a23.49,23.49,0,0,1-.3,3.3c-.2,1.35-.47,2.78-.82,4.28l-4.73,22.12h-6.37l5-22.65c0-.4.12-.86.22-1.39s.2-1.07.3-1.65.19-1.12.26-1.65a8.91,8.91,0,0,0,.12-1.31,4.59,4.59,0,0,0-1.95-4.09,8.44,8.44,0,0,0-4.8-1.31,11.75,11.75,0,0,0-5.22,1.28,14.81,14.81,0,0,0-4.38,3.22,10.53,10.53,0,0,0-2.7,3.64,26.79,26.79,0,0,0-1.58,4.46l-4.5,21.45h-6.3l11.25-53.55Z"
        />
        <path
          className="cls-1"
          d="M1341.61,179.34l-1.2,5.85h.15a15.14,15.14,0,0,1,6.08-5.07,18.35,18.35,0,0,1,7.72-1.68q5.48,0,8.14,2.4t2.66,7.57a23.49,23.49,0,0,1-.3,3.3c-.2,1.35-.47,2.78-.82,4.28l-4.73,22.12h-6.37l5-22.65c0-.4.12-.86.22-1.39s.2-1.07.3-1.65.19-1.12.26-1.65a8.91,8.91,0,0,0,.12-1.31,4.59,4.59,0,0,0-2-4.09,8.44,8.44,0,0,0-4.8-1.31,11.75,11.75,0,0,0-5.22,1.28,14.81,14.81,0,0,0-4.38,3.22,10.53,10.53,0,0,0-2.7,3.64,26.79,26.79,0,0,0-1.58,4.46l-4.5,21.45h-6.3l8.1-38.77Z"
        />
        <path
          className="cls-1"
          d="M1372.1,193.66a23.62,23.62,0,0,1,4.27-7.8,21.24,21.24,0,0,1,6.83-5.4,19.92,19.92,0,0,1,9.11-2q7.88,0,12,4.08t4.13,12a29.67,29.67,0,0,1-1.43,9.15,23.82,23.82,0,0,1-4.2,7.83,20.66,20.66,0,0,1-6.75,5.44,19.69,19.69,0,0,1-9.07,2,18.82,18.82,0,0,1-6.87-1.2,14.28,14.28,0,0,1-8.36-8.44,18.17,18.17,0,0,1-1.12-6.49A28.83,28.83,0,0,1,1372.1,193.66Zm7.61,16.8q2.7,2.92,8,2.93a10.8,10.8,0,0,0,6.19-1.8,16.76,16.76,0,0,0,4.5-4.54,21.33,21.33,0,0,0,2.77-6,21.66,21.66,0,0,0,.94-6.08,14.94,14.94,0,0,0-.6-4.27,8.9,8.9,0,0,0-5.06-5.82,11.08,11.08,0,0,0-4.54-.86,11.59,11.59,0,0,0-6.45,1.8,16.35,16.35,0,0,0-4.65,4.54,20.36,20.36,0,0,0-2.81,6,22.87,22.87,0,0,0-.94,6.3A10.93,10.93,0,0,0,1379.71,210.46Z"
        />
        <path
          className="cls-1"
          d="M1429.66,164.56l-11.25,53.55h-6.3l11.18-53.55Z"
        />
        <path
          className="cls-1"
          d="M1431.8,193.66a23.62,23.62,0,0,1,4.27-7.8,21.24,21.24,0,0,1,6.83-5.4,19.92,19.92,0,0,1,9.11-2q7.88,0,12,4.08t4.12,12a29.66,29.66,0,0,1-1.42,9.15,23.82,23.82,0,0,1-4.2,7.83,20.66,20.66,0,0,1-6.75,5.44,19.74,19.74,0,0,1-9.08,2,18.76,18.76,0,0,1-6.86-1.2,14.21,14.21,0,0,1-8.36-8.44,18.17,18.17,0,0,1-1.13-6.49A28.83,28.83,0,0,1,1431.8,193.66Zm7.61,16.8q2.7,2.92,7.95,2.93a10.8,10.8,0,0,0,6.19-1.8,16.76,16.76,0,0,0,4.5-4.54,21.33,21.33,0,0,0,2.77-6,21.66,21.66,0,0,0,.94-6.08,14.94,14.94,0,0,0-.6-4.27,9,9,0,0,0-5.06-5.82,11.08,11.08,0,0,0-4.54-.86,11.59,11.59,0,0,0-6.45,1.8,16.35,16.35,0,0,0-4.65,4.54,20.36,20.36,0,0,0-2.81,6,22.87,22.87,0,0,0-.94,6.3A10.93,10.93,0,0,0,1439.41,210.46Z"
        />
        <path
          className="cls-1"
          d="M1505.11,218.22a42.28,42.28,0,0,1-1.58,4.77,16.38,16.38,0,0,1-6.9,8.32,21.65,21.65,0,0,1-10.72,2.48,23.88,23.88,0,0,1-5.74-.68,14.84,14.84,0,0,1-4.84-2.1,11.05,11.05,0,0,1-3.41-3.71,11.55,11.55,0,0,1-1.39-5.51h6.3a6.24,6.24,0,0,0,1,3.18,6.06,6.06,0,0,0,2.17,1.95,9.21,9.21,0,0,0,3,1,24,24,0,0,0,3.52.26,12.06,12.06,0,0,0,6-1.35,11.56,11.56,0,0,0,3.86-3.52,18.41,18.41,0,0,0,2.37-5c.57-1.88,1.11-3.76,1.61-5.66l-.15-.15a11.91,11.91,0,0,1-5.25,4.5,17.17,17.17,0,0,1-6.83,1.35,16,16,0,0,1-6.34-1.17,11.82,11.82,0,0,1-4.53-3.3,14.61,14.61,0,0,1-2.74-5.06,20.9,20.9,0,0,1-.94-6.45,27.41,27.41,0,0,1,1.39-8.55,25.65,25.65,0,0,1,4-7.69,20.83,20.83,0,0,1,6.33-5.55,16.87,16.87,0,0,1,8.48-2.13,15,15,0,0,1,4.16.56,15.29,15.29,0,0,1,3.68,1.57,10.67,10.67,0,0,1,2.85,2.48,8.52,8.52,0,0,1,1.61,3.26v.15h.15l1.2-7.12h6l-7,33.15Q1505.86,215.49,1505.11,218.22Zm-24.45-11.88a9.87,9.87,0,0,0,1.76,3.3,8.36,8.36,0,0,0,3,2.28,9.55,9.55,0,0,0,4.2.87,11,11,0,0,0,6.27-1.8,15.09,15.09,0,0,0,4.38-4.58,22.14,22.14,0,0,0,2.59-6,24.36,24.36,0,0,0,.86-6.18,10.75,10.75,0,0,0-2.43-7.35q-2.45-2.78-7.47-2.78a9.72,9.72,0,0,0-5.92,1.88,16.68,16.68,0,0,0-4.28,4.61,22.49,22.49,0,0,0-2.62,5.92,21.83,21.83,0,0,0-.9,5.89A13,13,0,0,0,1480.66,206.34Z"
        />
        <path
          className="cls-1"
          d="M1525,228.2a16.49,16.49,0,0,1-2.62,2.92,10.93,10.93,0,0,1-3.23,1.95,11.26,11.26,0,0,1-4.2.72,10.54,10.54,0,0,1-2.32-.27,8.76,8.76,0,0,1-2.1-.71l1.12-5.17a7.5,7.5,0,0,0,1.8.75,7.15,7.15,0,0,0,1.8.3,5.2,5.2,0,0,0,3.53-1.17,12.37,12.37,0,0,0,2.47-3l3.9-6.38-6.82-38.77h6.82l4.5,30.82h.15l16.28-30.82h7l-25.65,45.3C1526.58,225.89,1525.78,227.07,1525,228.2Z"
        />
        <path
          className="cls-1"
          d="M1591,164.56l-11.25,53.55h-7.12l11.25-53.55Z"
        />
        <path
          className="cls-1"
          d="M1605.45,179.34l-1.2,5.85h.15a15.14,15.14,0,0,1,6.08-5.07,18.35,18.35,0,0,1,7.72-1.68c3.65,0,6.37.8,8.14,2.4s2.66,4.12,2.66,7.57a23.49,23.49,0,0,1-.3,3.3c-.2,1.35-.47,2.78-.82,4.28l-4.73,22.12h-6.37l5-22.65c0-.4.12-.86.22-1.39s.2-1.07.3-1.65.19-1.12.27-1.65a10.28,10.28,0,0,0,.11-1.31,4.59,4.59,0,0,0-2-4.09,8.44,8.44,0,0,0-4.8-1.31,11.67,11.67,0,0,0-5.21,1.28,14.73,14.73,0,0,0-4.39,3.22,10.53,10.53,0,0,0-2.7,3.64,26.79,26.79,0,0,0-1.58,4.46l-4.5,21.45h-6.3l8.1-38.77Z"
        />
        <path
          className="cls-1"
          d="M1635.53,185l1.12-5.62h6.6c.5-2,1-3.85,1.39-5.7a18.7,18.7,0,0,1,1.84-4.92,9.43,9.43,0,0,1,3.34-3.45,11.43,11.43,0,0,1,6-1.31c.9,0,1.8,0,2.7.11a13.47,13.47,0,0,1,2.62.49l-1,5.4c-.55-.1-1.07-.19-1.57-.26a9,9,0,0,0-1.43-.11,6.35,6.35,0,0,0-3.6.86,5.82,5.82,0,0,0-2,2.25,11.28,11.28,0,0,0-1,3.11c-.23,1.15-.52,2.33-.87,3.53H1657l-1,5.62h-7.5l-7.13,33.15h-6.37l7.2-33.15Z"
        />
        <path
          className="cls-1"
          d="M1658.14,193.66a23.64,23.64,0,0,1,4.28-7.8,21.21,21.21,0,0,1,6.82-5.4,20,20,0,0,1,9.11-2q7.88,0,12,4.08t4.13,12a29.67,29.67,0,0,1-1.43,9.15,23.82,23.82,0,0,1-4.2,7.83,20.66,20.66,0,0,1-6.75,5.44,19.69,19.69,0,0,1-9.07,2,18.72,18.72,0,0,1-6.86-1.2,14.26,14.26,0,0,1-8.37-8.44,18.17,18.17,0,0,1-1.12-6.49A29.11,29.11,0,0,1,1658.14,193.66Zm7.61,16.8q2.7,2.92,8,2.93a10.78,10.78,0,0,0,6.19-1.8,16.48,16.48,0,0,0,4.5-4.54,21.36,21.36,0,0,0,2.78-6,22,22,0,0,0,.93-6.08,14.94,14.94,0,0,0-.6-4.27,8.9,8.9,0,0,0-5.06-5.82,11,11,0,0,0-4.54-.86,11.59,11.59,0,0,0-6.45,1.8,16.35,16.35,0,0,0-4.65,4.54,20.61,20.61,0,0,0-2.81,6,23.25,23.25,0,0,0-.94,6.3A10.93,10.93,0,0,0,1665.75,210.46Z"
        />
        <path
          className="cls-1"
          d="M1712.18,179.34l-1.88,8.32h.15a21.18,21.18,0,0,1,5.74-6.6,13.51,13.51,0,0,1,8.36-2.62,8,8,0,0,0,1.13-.08,2.9,2.9,0,0,1,1.12.08l-1.5,6.82a4.49,4.49,0,0,0-1-.07h-.9q-1.2,0-2,0a9.82,9.82,0,0,0-1.88.34,12.27,12.27,0,0,0-4.68,2.33,16.39,16.39,0,0,0-3.57,4,15.71,15.71,0,0,0-2,4.39c-.5,1.67-.9,3.24-1.2,4.69l-3.68,17.17h-6.3l8-38.77Z"
        />
        <path
          className="cls-1"
          d="M1737.23,179.34l-1.43,5.77.15.15a18.56,18.56,0,0,1,6-5,16,16,0,0,1,7.54-1.83,12,12,0,0,1,6.3,1.57,7.83,7.83,0,0,1,3.45,5.4,16.69,16.69,0,0,1,5.82-5.06,15.48,15.48,0,0,1,7.46-1.91,16.53,16.53,0,0,1,4,.48,11.55,11.55,0,0,1,3.49,1.5,7.46,7.46,0,0,1,2.44,2.67,8.36,8.36,0,0,1,.9,4,23.25,23.25,0,0,1-.38,3.9c-.25,1.45-.5,2.72-.75,3.82l-4.87,23.25h-6.38l5.4-24.6a21.64,21.64,0,0,0,.41-2.14,13.51,13.51,0,0,0,.19-2.06,4.8,4.8,0,0,0-1.69-3.75,6.11,6.11,0,0,0-4.23-1.5,8.83,8.83,0,0,0-2.74.45,15,15,0,0,0-2.66,1.16,15,15,0,0,0-4.35,3.72,11,11,0,0,0-2.33,4,43.53,43.53,0,0,0-1.27,4.73l-4.28,20H1747l4.65-22.12c.35-1.5.64-2.9.86-4.2a20.19,20.19,0,0,0,.34-2.63,5,5,0,0,0-1.39-3.75,5.53,5.53,0,0,0-4-1.35,10.34,10.34,0,0,0-5.22,1.5,17.3,17.3,0,0,0-4.31,3.45,10.93,10.93,0,0,0-2.77,3.94,33.21,33.21,0,0,0-1.35,4.84l-4.35,20.32h-6.3l8.17-38.77Z"
        />
        <path
          className="cls-1"
          d="M1793.29,185.45a14.1,14.1,0,0,1,3.79-3.94,15.83,15.83,0,0,1,5.1-2.32,23.15,23.15,0,0,1,5.92-.75,31.06,31.06,0,0,1,5.4.45,13.69,13.69,0,0,1,4.5,1.57,8.55,8.55,0,0,1,3.11,3,9.13,9.13,0,0,1,1.17,4.84,16.68,16.68,0,0,1-.42,3.56c-.27,1.27-.53,2.44-.78,3.49L1818.6,206c-.15.8-.36,1.7-.64,2.7a11,11,0,0,0-.41,2.85,2.64,2.64,0,0,0,.53,1.88,1.82,1.82,0,0,0,1.35.52,3.12,3.12,0,0,0,1-.19l1-.33-1,4.72a12.34,12.34,0,0,1-1.92.56,8.91,8.91,0,0,1-2,.27,5.23,5.23,0,0,1-4-1.43,4.69,4.69,0,0,1-1-4l-.15-.15a13.35,13.35,0,0,1-5,4.12,18.69,18.69,0,0,1-8,1.43,17.94,17.94,0,0,1-4.62-.57,9.5,9.5,0,0,1-3.71-1.87,9.3,9.3,0,0,1-2.51-3.38,12.1,12.1,0,0,1-.94-5.06,10.71,10.71,0,0,1,1.84-6.67,11.57,11.57,0,0,1,4.69-3.64,23.14,23.14,0,0,1,6.3-1.69q3.45-.45,6.67-.75c1.7-.15,3.16-.31,4.39-.49a9.91,9.91,0,0,0,3-.86,3.81,3.81,0,0,0,1.76-1.72,7.06,7.06,0,0,0,.56-3.08,4.07,4.07,0,0,0-.75-2.55,5.39,5.39,0,0,0-1.87-1.54,8.85,8.85,0,0,0-2.52-.78,16.59,16.59,0,0,0-2.66-.23,17,17,0,0,0-3.56.38,10.28,10.28,0,0,0-3.19,1.23,8.35,8.35,0,0,0-2.4,2.18,7.13,7.13,0,0,0-1.27,3.19h-6.38A13.45,13.45,0,0,1,1793.29,185.45Zm1.8,26.59a8.86,8.86,0,0,0,4.84,1.35q7.58,0,10.87-5.1a12.73,12.73,0,0,0,2.14-4.73c.37-1.7.79-3.45,1.24-5.25H1814a8.74,8.74,0,0,1-3.42,1.39c-1.37.27-2.83.49-4.38.64s-3.12.33-4.69.56a18.11,18.11,0,0,0-4.24,1.09,7.84,7.84,0,0,0-3.07,2.13A6,6,0,0,0,1793,208,4.47,4.47,0,0,0,1795.09,212Z"
        />
        <path
          className="cls-1"
          d="M1850.55,179.34l-1.2,5.62h-7.72l-4.8,22.8c0,.45-.1.88-.15,1.28a10.18,10.18,0,0,0-.08,1.27,2.08,2.08,0,0,0,1.16,2.06,6,6,0,0,0,2.67.57,9.48,9.48,0,0,0,1.87-.19,17.19,17.19,0,0,0,1.8-.49l-1.12,5.85c-.75.1-1.5.19-2.25.26a21.2,21.2,0,0,1-2.25.12,20,20,0,0,1-3.38-.27,7.61,7.61,0,0,1-2.74-1,5.52,5.52,0,0,1-1.87-1.91,5.93,5.93,0,0,1-.71-3.08,9.92,9.92,0,0,1,.11-1.57c.07-.5.16-1.05.26-1.65l5.1-24.08h-6.52l1.2-5.62h6.52l2.4-11.48h6.38l-2.4,11.48Z"
        />
        <path
          className="cls-1"
          d="M1864,179.34l-8.1,38.77h-6.3l8-38.77Zmhttps://www.ncbi.nlm.nih.gov/pmc/articles/5815332-5-7,1.65-7.8h6.45l-1.65,7.8Z"
        />
        <path
          className="cls-1"
          d="M1869.26,193.66a23.64,23.64,0,0,1,4.28-7.8,21.21,21.21,0,0,1,6.82-5.4,20,20,0,0,1,9.11-2q7.88,0,12,4.08t4.13,12a29.67,29.67,0,0,1-1.43,9.15,23.82,23.82,0,0,1-4.2,7.83,20.66,20.66,0,0,1-6.75,5.44,19.69,19.69,0,0,1-9.07,2,18.72,18.72,0,0,1-6.86-1.2,14.26,14.26,0,0,1-8.37-8.44,18.17,18.17,0,0,1-1.12-6.49A29.11,29.11,0,0,1,1869.26,193.66Zm7.61,16.8q2.7,2.92,8,2.93a10.78,10.78,0,0,0,6.19-1.8,16.48,16.48,0,0,0,4.5-4.54,21.36,21.36,0,0,0,2.78-6,22,22,0,0,0,.93-6.08,14.94,14.94,0,0,0-.6-4.27,8.9,8.9,0,0,0-5.06-5.82,11,11,0,0,0-4.54-.86,11.59,11.59,0,0,0-6.45,1.8,16.35,16.35,0,0,0-4.65,4.54,20.61,20.61,0,0,0-2.81,6,23.25,23.25,0,0,0-.94,6.3A10.93,10.93,0,0,0,1876.87,210.46Z"
        />
        <path
          className="cls-1"
          d="M1923.52,179.34l-1.2,5.85h.15a15.14,15.14,0,0,1,6.08-5.07,18.35,18.35,0,0,1,7.72-1.68c3.65,0,6.37.8,8.14,2.4s2.66,4.12,2.66,7.57a23.49,23.49,0,0,1-.3,3.3c-.2,1.35-.47,2.78-.82,4.28l-4.73,22.12h-6.37l5-22.65c0-.4.12-.86.22-1.39s.2-1.07.3-1.65.19-1.12.27-1.65a10.28,10.28,0,0,0,.11-1.31,4.59,4.59,0,0,0-2-4.09,8.44,8.44,0,0,0-4.8-1.31,11.75,11.75,0,0,0-5.22,1.28,14.81,14.81,0,0,0-4.38,3.22,10.53,10.53,0,0,0-2.7,3.64,26.79,26.79,0,0,0-1.58,4.46l-4.5,21.45h-6.3l8.1-38.77Z"
        />
      </g>
    </g>
  </svg>
);
