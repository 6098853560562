import Card from "react-bootstrap/Card";
import scopusLogo from "../../../../../images/resize/scopus-logo.png";

function ScopusIssnResume({ content }) {
  //const content = useSelector(state => state.content.value.issnData.ScopusIssn)
  // console.log(content)

  return (
    <Card
      className="text-center bg-scopus-gradient"
      style={{ alignItems: "center" }}
      text="black"
    >
      <a
        href={content.link ?? `https://www.scopus.com/home.uri`}
        target="_blank"
        rel="noreferrer"
      >
        <Card.Img
          variant="top"
          alt="Scopus"
          style={{ marginTop: "1rem", width: "100%" }}
          src={scopusLogo}
        />
      </a>
      <Card.Body>
        <Card.Title> </Card.Title>
        <Card.Text>
          {!content.notFound ? (
            <>
              <p
                style={{
                  color: "#445e5f",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
              >
                CiteScore<br></br>
                <a
                  href={content.link}
                  style={{
                    color: "#445e5f",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {content.citeScoreYearInfoList.citeScoreCurrentMetric} -{" "}
                  {content.citeScoreYearInfoList.citeScoreCurrentMetricYear}
                </a>
              </p>
              <p
                style={{
                  color: "#445e5f",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
              >
                Tracker<br></br>
                <a
                  style={{
                    color: "#445e5f",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                  href={content.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {content.citeScoreYearInfoList.citeScoreTracker} -{" "}
                  {content.citeScoreYearInfoList.citeScoreTrackerYear}
                </a>
              </p>
            </>
          ) : (
            <p style={{ fontSize: "20px", marginTop: "11px" }}>
              {content.error}
            </p>
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default ScopusIssnResume;
