import Card from "react-bootstrap/Card";
//import doajLogo from '../../../../../images/doajLogo.png'
import { Button, Col, Row } from "react-bootstrap";
import { svgSealDOAJ, svgLogoDOAJ } from "../../contants";
import Badge from "react-bootstrap/Badge";
import { BsCheck2Circle } from "react-icons/bs";
import doajLogo from "../../../../../images/resize/DOAJ-logo-v1.png";

function DoajDoiDetails({ content }) {
  return (
    <>
      <Card
        className="tab-color-doaj-detail"
        bg="light"
        style={{ marginTop: "1rem", height: "100%" }}
      >
        <Card.Body>
          <Row style={{ alignItems: "center" }}>
            <Col xs={12} sm={3}>
              <a href={content.link} target="_blank" rel="noreferrer">
                <Card.Img
                  style={{ width: "8rem" }}
                  variant="top"
                  alt="Doaj Logo"
                  src={doajLogo}
                />
              </a>
            </Col>
            {content.notFound ? (
              <Col style={{ textAlign: "center" }}>Recurso no encontrado.</Col>
            ) : (
              <>
                <Col xs={12} sm={4}>
                  {content.seal ? (
                    <>
                      <p>
                        <BsCheck2Circle /> Indexado
                      </p>
                      <Button
                        variant="light"
                        href="https://doaj.org/apply/seal"
                      >
                        {svgSealDOAJ}
                      </Button>
                    </>
                  ) : (
                    <p>
                      <BsCheck2Circle /> Indexado
                    </p>
                  )}
                </Col>

                <Col xs={12} sm={5} style={{ alignItems: "center" }}>
                  {content.keywords ? (
                    <>
                      <b>Keywords</b>
                      <br></br>
                      {content.keywords.map((keyword, index) => (
                        <Badge key={index} pill bg="dark">
                          {keyword}
                        </Badge>
                      ))}
                    </>
                  ) : null}
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default DoajDoiDetails;
