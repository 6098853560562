import { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

export default function ChartsBar(citedYear) {
  const { citedYear: arrayCitedYear } = citedYear;

  // Ordena el arrayCitedYear en función de los años
  const sortedArrayCitedYear = useMemo(() => {
    return [...arrayCitedYear].sort((a, b) => a.year - b.year);
  }, [arrayCitedYear]);

  const data = useMemo(
    function () {
      return {
        datasets: [
          {
            label: "Citas por año",
            tension: 0.3,
            data: sortedArrayCitedYear.map(function (item) {
              return item.cited_by_count;
            }),
            borderColor: "red",
            backgroundColor: " #ffaaaa ",
          },
        ],
        labels: sortedArrayCitedYear.map(function (item) {
          return item.year;
        }),
      };
    },
    [sortedArrayCitedYear],
  );

  return <Bar data={data} options={options} />;
}
