import CardGroup from "react-bootstrap/CardGroup";
import OpenAlexIssnResume from "./ISSN/OpenAlexIssnResume";
import DoajIssnResume from "./ISSN/DoajIssnResume";
import WosIssnResume from "./ISSN/WosIssnResume";
import ScopusIssnResume from "./ISSN/ScopusIssnResume";
import RedibIssnResume from "./ISSN/RedibIssnResume";
import ScimagoIssnResume from "./ISSN/ScimagoIssnResume";
//import { crossrefISSNConfig, openAlexIssnConfig, doajIssnConfig, wosConfig, scopusConfig, rebidConfig, scimagoConfig } from '../../../../helpers/getConfig';
import { useSelector } from "react-redux";
import CrossrefIssnResume from "./ISSN/CrossrefIssnResume";
import { getGlobalConfig, getApiConfig } from "../../../../config";

function ListCardIssnResume({ content }) {
  const config = getGlobalConfig();
  const openAlexIssnConfig = getApiConfig(config, "ISSN", "OpenAlexISSN");
  const doajIssnConfig = getApiConfig(config, "ISSN", "DoajISSN");
  const wosConfig = getApiConfig(config, "ISSN", "WosISSN");
  const scopusConfig = getApiConfig(config, "ISSN", "ScopusIssn");
  const rebidConfig = getApiConfig(config, "ISSN", "RedibISSN");
  const scimagoConfig = getApiConfig(config, "ISSN", "ScimagoIssn");
  const crossrefISSNConfig = getApiConfig(config, "ISSN", "CrossrefISSN");

  const criteria = useSelector((state) => state.search.criteria);
  let data = content;
  if (criteria === "DOI") {
    data = content.issnData;
  }

  const issnValue = content.issn;
  const publisher = data.publisher;
  const url = data.URL;
  const title = data.title;

  return (
    <>
      {criteria === "ISSN" ? null : (
        <h4 style={{ color: "grey" }}>
          Publicado en:{" "}
          <a
            href={url}
            style={{ color: "#ffaaaa" }}
            target="_blank"
            rel="noreferrer"
          >
            {title}{" "}
          </a>{" "}
          <span style={{ fontSize: "18px", color: "grey" }}>
            ({issnValue}), {publisher}
          </span>{" "}
        </h4>
      )}

      <CardGroup style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        {crossrefISSNConfig.enabled ? (
          <CrossrefIssnResume content={data.CrossrefISSN} />
        ) : null}
        {openAlexIssnConfig.enabled ? (
          <OpenAlexIssnResume content={data.OpenAlexISSN} />
        ) : null}
        {doajIssnConfig.enabled ? (
          <DoajIssnResume
            content={data.DoajISSN}
            issnValue={data.identifier.value}
          />
        ) : null}
        {rebidConfig.enabled ? (
          <RedibIssnResume
            content={data.RedibISSN}
            issnValue={data.identifier.value}
          />
        ) : null}
        {wosConfig.enabled ? <WosIssnResume content={data.WosISSN} /> : null}
        {scopusConfig.enabled ? (
          <ScopusIssnResume content={data.ScopusIssn} />
        ) : null}
        {scimagoConfig.enabled ? (
          <ScimagoIssnResume content={data.ScimagoIssn} />
        ) : null}
      </CardGroup>
    </>
  );
}

export default ListCardIssnResume;
