import Card from "react-bootstrap/Card";
import { Col, Row } from "react-bootstrap";
import semanticLogo from "../../../../../images/resize/semanticScholar-blue.png";

function SemanticScholarDoiDetails({ content }) {
  return (
    <>
      <Card
        className="tab-color-semantic-detail"
        bg="light"
        style={{ marginTop: "1rem", height: "100%" }}
      >
        <Card.Body>
          <Row>
            <Col xs={12} sm={3}>
              <a href={content.url} target="_blank" rel="noreferrer">
                <Card.Img
                  style={{ width: "8rem" }}
                  variant="top"
                  alt="Doaj Logo"
                  src={semanticLogo}
                />
              </a>
            </Col>
            <Col>
              {content.notFound ? (
                <p>Recurso no encontrado.</p>
              ) : (
                <>
                  <span>
                    <b>{content.numCitedBy}</b> citas en otros artículos.
                  </span>
                  <br></br>
                  <span>
                    <b>{content.citationVelocity}</b> citas nuevas en los
                    últimos tres años.
                  </span>
                  <br></br>
                  <p>
                    <b>{content.influentialCitationCount}</b> citas fueron
                    influyentes en otros trabajos.
                  </p>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default SemanticScholarDoiDetails;
